/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import mapboxgl from 'mapbox-gl';
import {
    MapFullscreenControlv2 as MapFullscreenControlV2,
    MapLayerControl,
    MapAttributionControl,
    announce,
    MapResetPosition,
    Map3DToggle,
    MapSearchButtonControl,
    MapFilterControl,
    ShowListButtonControl,
    MapControlContainer,
} from 'sarsaparilla';
import MapLegendControl from './MapLegendControl';
import CampingLegendControl from './CampingLegendControl';
import MapSearchAreaControl from './MapSearchAreaControl';
import MapSearchLayerControl from './MapSearchLayerControl';
import ExpandMapControl from './ExpandMapButton';

export const addMapControls = (map, params) => {
    let basicControls = {};
    if (!map.current) return basicControls;
    const attributionControl = new MapAttributionControl();
    const navigationControl = new mapboxgl.NavigationControl({ visualizePitch: true });
    const mapScaleControl = new mapboxgl.ScaleControl({
        maxWidth: 150,
        unit: 'imperial',
    });

    map.current.addControl(attributionControl, 'bottom-right');
    map.current.addControl(navigationControl, 'top-left');
    map.current.addControl(mapScaleControl);

    basicControls = {
        attribution: attributionControl,
        scale: mapScaleControl,
    };

    if (params.useLayerPanels) {
        const layersPanelControl = new MapControlContainer();
        map.current.addControl(layersPanelControl, 'top-right');
        layersPanelControl.show();
        basicControls.layersPanelControl = layersPanelControl;
    }

    if (!params.ignoreDownload) {
        const downloadControl = new MapFilterControl();
        map.current.addControl(downloadControl, 'bottom-right');
        downloadControl.show();
        basicControls.downloadControl = downloadControl;
    }

    if (!params.ignoreFullscreen) {
        const fullscreenControl = new MapFullscreenControlV2({
            useModalStyling: params.useModalStyling || false,
        });
        map.current.addControl(fullscreenControl);
        basicControls.fullscreen = fullscreenControl;
    }

    if (!params.ignoreLayers) {
        const mapLayerControl = new MapLayerControl();
        map.current.addControl(mapLayerControl);
        basicControls.layer = mapLayerControl;
    }

    if (params.map3d) {
        const map3DToggle = new Map3DToggle();
        map.current.addControl(map3DToggle);
        basicControls.map3d = map3DToggle;
    }

    if (params.geolocation) {
        const geoParams = {
            positionOptions: { enableHighAccuracy: false },
            fitBoundsOptions: { maxZoom: 9 },
            trackUserLocation: true,
        };
        const geolocation = new mapboxgl.GeolocateControl(geoParams);
        map.current.addControl(geolocation, 'top-left');
    }

    navigationControl._zoomInButton.onclick = () => {
        announce('Zoom in button selected. You have zoomed in the map');
    };
    navigationControl._zoomOutButton.onclick = () => {
        announce('Zoom out button selected. You have zoomed out the map');
    };
    navigationControl._compass.onclick = () => {
        announce(
            'Compass button selected. Map has returned to the default north orientation'
        );
    };

    basicControls.navigation = navigationControl;

    return basicControls;
};

export const addLegendControls = (map, props) => {
    const legendComponent = (
        <CampingLegendControl campsites={props.campsites} amenities={props.amenities} />
    );
    const legendControl = new MapLegendControl({ listContent: legendComponent });
    map.current.addControl(legendControl, 'bottom-right');
    return legendControl;
};

export const addLayerControls = (map, props) => {
    const layerControl = new MapLayerControl(props.layers);
    map.current.addControl(layerControl);
    return layerControl;
};

export const addMapSpecialControls = (map, params) => {
    const specialControls = {};
    if (params.showList && params.toggleFunc) {
        const showList = new ShowListButtonControl({ onClick: params.toggleFunc });
        map.current.addControl(showList, 'top-left');
        showList.hide();
        specialControls.showList = showList;
    }

    if (params.legend && params.legendContent) {
        const { legendContent, getCurrentZoom, maxZoom, hideStatusLegend } = params;
        const legendControl = new MapLegendControl({
            listContent: legendContent,
            hideStatusLegend,
        });
        map.current.addControl(legendControl, 'bottom-right');
        if (getCurrentZoom && maxZoom)
            legendControl.updateGlobalContent({
                maxZoom,
                currentZoom: params.getCurrentZoom(),
            });
        specialControls.legend = legendControl;
    }

    if (params.searchArea && params.searchAreaFunc) {
        const searchArea = new MapSearchAreaControl(params.searchAreaFunc);
        map.current.addControl(searchArea, 'top-left');
        searchArea.hide();
        specialControls.searchArea = searchArea;
    }

    if (params.searchLayers && params.layerContent) {
        const searchLayers = new MapSearchLayerControl(...params.layerContent);
        map.current.addControl(searchLayers);
        specialControls.searchLayers = searchLayers;
    }

    if (params.resetPosition) {
        const resetControl = new MapResetPosition({ features: params.data });
        map.current.addControl(resetControl, 'top-left');
        specialControls.resetPosition = resetControl;
    }

    if (params.map3d) {
        const map3DToggle = new Map3DToggle();
        map.current.addControl(map3DToggle, 'top-left');
        specialControls.map3d = map3DToggle;
    }

    if (params.searchNearby) {
        const searchNearby = new MapSearchButtonControl(params.searchNearbyParams);
        map.current.addControl(searchNearby, 'top-left');
        searchNearby.hide();
        specialControls.searchNearby = searchNearby;
    }

    if (params.searchNearbyFilter) {
        const searchNearbyFilter = new MapFilterControl();
        map.current.addControl(searchNearbyFilter, 'top-left');
        searchNearbyFilter.hide();
        specialControls.searchNearbyFilter = searchNearbyFilter;
    }

    if (params.expandButton) {
        const expandMap = new ExpandMapControl(params.expandButton);
        map.current.addControl(expandMap, 'top-left');
        specialControls.expandButton = expandMap;
    }

    return specialControls;
};

export const getControlsPerService = (service) => {
    const controls = {
        pass: { gesture: true, useModalStyling: true },
        destination: { gesture: true },
        treepermit: { gesture: true },
        ticket: { useModalStyling: true },
        campingpoi: { gesture: true },
        navigation: { geolocation: true, ignoreFullscreen: true, gesture: true },
        gateway: {
            geolocation: true,
            gesture: true,
            ignoreFullscreen: true,
            ignoreLayers: true,
            useLayerPanels: true,
        },
        search: { ignoreLayers: true, useLayerPanels: true },
        camping: { ignoreLayers: true, useModalStyling: true },
        campingitinerary: { ignoreLayers: true, useModalStyling: true },
        permit: { gesture: true },
        tripplanner: { ignoreLayers: true },
        campingsinglepoint: { useModalStyling: true },
        campinginternal: { useModalStyling: true },
        licensingColorado: {
            ignoreDownload: true,
            ignoreFullscreen: true,
            useModalStyling: true,
        },
        licensingColoradoSingle: {
            ignoreDownload: true,
            ignoreFullscreen: true,
            useModalStyling: true,
        },
    };

    return controls[service] || {};
};

export const getSpecialControlsPerService = (service) => {
    const controls = {
        navigation: {
            showList: true,
            legend: true,
            hideStatusLegend: true,
            searchNearbyFilter: true,
        },
        gateway: {
            legend: true,
        },
        search: {
            legend: true,
            searchArea: true,
        },
        camping: {
            resetPosition: true,
            map3d: true,
            searchNearby: true,
            searchNearbyFilter: true,
        },
        tripplanner: {
            legend: true,
            searchLayers: true,
        },
        internalcampingfieldsales: {
            ignoreDownload: true,
        },
        campinginternal: {
            ignoreDownload: true,
        },
        licensingColorado: {
            searchArea: true,
        },
    };
    return controls[service] || {};
};

// cSpell:ignore geolocate, campinginternal, campingitinerary, campingpoi, campingsinglepoint, Controlv, internalcampingfieldsales, treepermit, tripplanner
