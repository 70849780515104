/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Icons, ErrorBoundary, MapHelper } from 'sarsaparilla';
import MapCustomControl from './MapCustomControl';
import { FACILITY_TYPES, SITE_STATUS } from '../constants';

function LegendContent({ listContent, listContainer, btn }) {
    React.useEffect(() => {
        const container = listContainer;
        const button = btn;

        container.style.display = 'none';
        container.querySelector('div button').addEventListener('click', () => {
            container.style.display = 'none';
            button.style.display = 'block';
        });
    }, [btn, listContainer]);

    return (
        <ErrorBoundary>
            <div className="map-legend-control-list-button-container">
                <button type="button">
                    <span>
                        <strong> Legend </strong>
                    </span>
                    <span>
                        <Icons.IconChevronDown />
                    </span>
                </button>
            </div>
            <div className="map-legend-control-list-icon">{listContent}</div>
        </ErrorBoundary>
    );
}

LegendContent.propTypes = {
    listContent: PropTypes.node.isRequired,
    listContainer: PropTypes.object.isRequired,
    btn: PropTypes.object.isRequired,
};

export default class MapLegendControl extends MapCustomControl {
    constructor({ listContent, hideStatusLegend }) {
        super({ listContent, hideStatusLegend });
        this._listContent = listContent;
        this._isClustered = false;
        this._hideStatusLegend = hideStatusLegend;
        this.updateContent = this.updateContent.bind(this);
        this._pinColors = MapHelper.circleIconsColors.reduce((acc, current) => {
            acc[current[0]] = current[1];
            return acc;
        }, {});
    }

    onAdd(map) {
        super.onAdd(map);
        this._container.className =
            'map-legend-control mapboxgl-ctrl mapboxgl-ctrl-text-group';
        this._listContainer = document.createElement('div');
        this._listContainer.className = 'map-legend-control-list';
        this._listContainer.style.display = 'none';

        this._btn = document.createElement('button');
        this._btn.innerHTML = 'Legend';
        this._btn.setAttribute('aria-label', 'View the map legend');
        this._btn.addEventListener('click', this._onClick);
        this._container.appendChild(this._btn);
        this._container.appendChild(this._listContainer);

        this.root = createRoot(this._listContainer);

        this.updateContent(this._listContent, () => {
            // note this is a callback, ReactDOM.render() is asynchronous and not guaranteed to execute in order
            this._listContainer.style.display = 'none';
            this._listContainer
                .querySelector('div button')
                .addEventListener('click', () => {
                    this._listContainer.style.display = 'none';
                    this._btn.style.display = 'block';
                });
        });

        return this._container;
    }

    _onClick = () => {
        this._btn.style.display = 'none';
        this._listContainer.style.display = 'block';
    };

    updateContent = (listContent) => {
        if (this.root) {
            this.root.render(
                <LegendContent
                    listContent={listContent}
                    listContainer={this._listContainer}
                    btn={this._btn}
                />
            );
        }
    };

    getStatusContent = () => {
        const statuses = [
            {
                iconName: <span className="full-circle-availability circle-available" />,
                displayNames: 'Available for selected dates',
                key: 'available',
            },
            {
                iconName: <span className="half-circle-availability" />,
                displayNames: 'Partially available or no dates',
                key: 'partially-available',
            },
            {
                iconName: (
                    <span className="full-circle-availability circle-unavailable" />
                ),
                displayNames: 'Unavailable',
                key: 'unavailable',
            },
        ];

        return (
            <div className="legend-section">
                <li key="status-segment" className="legend-section-title">
                    {SITE_STATUS}
                </li>

                {statuses.map((status) => {
                    return (
                        <li key={status.key} className="availability-status-li">
                            <span className="availability-status-icon">
                                {status.iconName}
                            </span>
                            <span>{status.displayNames}</span>
                        </li>
                    );
                })}
            </div>
        );
        s;
    };

    getFacilityContent = (isClustered) => {
        return (
            <div className="legend-section">
                <li key="facility-segment" className="legend-section-title">
                    {FACILITY_TYPES}
                </li>
                <li>
                    {isClustered ? (
                        <span
                            className="circle-pin"
                            style={{ backgroundColor: `${this._pinColors.camping_pin}` }}
                        />
                    ) : (
                        <img
                            alt=""
                            src={`${process.env.CDN}/img/search/camping_pin@2x.png`}
                        />
                    )}
                    <span>Campground</span>
                </li>
                <li>
                    {isClustered ? (
                        <span
                            className="circle-pin"
                            style={{ backgroundColor: `${this._pinColors.dayUse_pin}` }}
                        />
                    ) : (
                        <img
                            alt=""
                            src={`${process.env.CDN}/img/search/dayUse_pin@2x.png`}
                        />
                    )}
                    <span>Day Use</span>
                </li>
                <li>
                    {isClustered ? (
                        <span
                            className="circle-pin"
                            style={{ backgroundColor: `${this._pinColors.permit_pin}` }}
                        />
                    ) : (
                        <img
                            alt=""
                            src={`${process.env.CDN}/img/search/permit_pin@2x.png`}
                        />
                    )}
                    <span>Permit</span>
                </li>
                <li>
                    {isClustered ? (
                        <span
                            className="circle-pin"
                            style={{ backgroundColor: `${this._pinColors.poi_pin}` }}
                        />
                    ) : (
                        <img
                            alt=""
                            src={`${process.env.CDN}/img/search/poi_pin@2x.png`}
                        />
                    )}
                    <span>Point of Interest</span>
                </li>
                <li>
                    {isClustered ? (
                        <span
                            className="circle-pin"
                            style={{ backgroundColor: `${this._pinColors.pass_pin}` }}
                        />
                    ) : (
                        <img
                            alt=""
                            src={`${process.env.CDN}/img/search/pass_pin@2x.png`}
                        />
                    )}
                    <span>Pass</span>
                </li>
                <li>
                    {isClustered ? (
                        <span
                            className="circle-pin"
                            style={{ backgroundColor: `${this._pinColors.recArea_pin}` }}
                        />
                    ) : (
                        <img
                            alt=""
                            src={`${process.env.CDN}/img/search/recArea_pin@2x.png`}
                        />
                    )}
                    <span>Recreation Area</span>
                </li>
                <li>
                    {isClustered ? (
                        <span
                            className="circle-pin"
                            style={{ backgroundColor: `${this._pinColors.tour_pin}` }}
                        />
                    ) : (
                        <img
                            alt=""
                            src={`${process.env.CDN}/img/search/tour_pin@2x.png`}
                        />
                    )}
                    <span>Tour</span>
                </li>
                <li>
                    {isClustered ? (
                        <span
                            className="circle-pin"
                            style={{
                                backgroundColor: `${this._pinColors.venue_other_pin}`,
                            }}
                        />
                    ) : (
                        <img
                            alt=""
                            src={`${process.env.CDN}/img/search/venue_other_pin@2x.png`}
                        />
                    )}
                    <span>Venues</span>
                </li>
            </div>
        );
    };

    getGlobalContent = (isClustered) => {
        return (
            <ul>
                {!this._hideStatusLegend && this.getStatusContent()}
                {this.getFacilityContent(isClustered)}
            </ul>
        );
    };

    updateGlobalContent = ({ maxZoom, currentZoom }) => {
        const shouldCluster = currentZoom < maxZoom;
        if (shouldCluster !== this.isClustered) {
            this.isClustered = shouldCluster;
            this.updateContent(this.getGlobalContent(shouldCluster));
        }
    };
}
