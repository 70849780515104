/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import { Stack, Text } from 'sarsaparilla';
import type {
    LotteriesOnSiteText,
    LotteryAccordionItems,
    LotteryStatisticsDescription,
} from '../../../types/constants/lotteryTransparencyTest.types';

export const lotteriesOnSiteText: LotteriesOnSiteText = `<p>Lotteries are for highly competitive experiences and locations on ${process.env.SITE_NAME}, where demand far exceeds capacity. ${process.env.SITE_NAME} offers a lottery system for federal agencies to use as a tool to:</p>
<div class="lottery-list">
<ul class="lottery-list-items">
  <li>provide fair access to those interested in competing for the recreation opportunity,</li>
  <li>support health and safety measures, including space restrictions,</li>
  <li>prevent damage to the natural landscape, and</li>
  <li>preserve the visitor experience.</li>
</ul>
</div>`;

export const lotteryAccordionItems: LotteryAccordionItems = [
    {
        heading: 'What are Standard Lotteries?',
        content: (
            <Stack space="sm">
                <Text>
                    A standard lottery is commonly used when an agency requires applicants
                    to select a preference related to a specific criteria the agency uses
                    to manage capacity. Those preference selections may include date(s),
                    trailhead or zone, time slot, or group size.
                </Text>
                <Text>Features of the standard lottery:</Text>

                <ul>
                    <li>
                        <Text>
                            A lottery application period is opened at a designated time
                            for each recreation opportunity (example: a river permit,
                            wilderness permit, special event, etc.), and requires
                            applicants to submit an online application during this time
                            frame. It doesn’t matter when the application is submitted
                            during the open application period. At the end of the lottery
                            application period, all applications are included in an
                            automated and random process providing an equal chance at
                            being selected for the opportunity.
                        </Text>
                    </li>

                    <li>
                        <Text>
                            Successful and unsuccessful lottery applicants will be
                            notified following the application period. Often, there are
                            additional steps that the successful lottery applicants must
                            follow to secure and pay for the reservation.
                        </Text>
                    </li>
                </ul>
            </Stack>
        ),
    },
    {
        heading: 'What are Early Access Lotteries?',
        content: (
            <Stack space="sm">
                <Text>
                    The Early Access Lottery assigns a limited number of successful
                    lottery applicants a time slot before the general on-sale during which
                    they can make a reservation. While the lottery does not guarantee a
                    preferred reservation date or itinerary, it limits the number of users
                    competing simultaneously for availability allowing for a less
                    competitive and hurried reservation experience.
                </Text>
                <Text>Features of the early access lottery:</Text>

                <ul>
                    <li>
                        Agency managers determine the number of individuals (successful
                        lottery applicants) competing at one time during a time slot. The
                        number of successful applicants is a conservative estimate made by
                        the agency to ensure those participating in the Early Access
                        Period have a reasonable chance to secure a desired trip.
                    </li>
                    <li>
                        Once the Early Access Period opens, successful applicants can log
                        in to their {process.env.SITE_NAME} account to view real-time
                        available inventory and may only make a reservation once their
                        time slot opens. These applicants know immediately if their
                        reservations are accepted.
                    </li>
                    <li>
                        Successful applicants with the earliest time slot have the most
                        options to build a complete trip itinerary or secure permits for
                        their desired date ranges. Those with a later time slot might not
                        have access to their preferred itinerary or dates but can create
                        an alternate trip using available inventory.
                    </li>
                    <li>
                        Successful applicants will be competing for inventory during their
                        assigned time slot with others who have been assigned that same
                        time slot and those individuals with an earlier assigned time
                        slot.
                    </li>
                    <li>
                        After the designated Early Access Period closes, remaining
                        inventory may open to the public for the general on-sale.
                    </li>
                </ul>
            </Stack>
        ),
    },
    {
        heading: 'How Random is the Lottery Process?',
        content: (
            <Stack space="sm">
                <Text>
                    Each lottery is randomized by shuffling all applications using the
                    Fisher-Yates Shuffle, which produces an unbiased and random ordering
                    of results. We also use a Cryptographically Secure Pseudo Random
                    Number Generator (CSPRNG) to prevent any inadvertent bias in the
                    lottery process. These random number generators are vetted to produce
                    random numbers that cannot be predicted based upon past outputs, and
                    they don&apos;t allow anyone to predict future or past numbers
                    generated.
                </Text>
            </Stack>
        ),
    },
];

export const lotteryStatisticsDescription: LotteryStatisticsDescription = (
    <Text size="lg">
        We hope these statistics, provided by the federal agencies, give you a sense for
        the competitive nature of these experiences and help you make more informed
        decisions about applying for a lottery. While the statistics demonstrate the
        demand, they should not be used to predict a success rate.
    </Text>
);

// cSpell:ignore CSPRNG
