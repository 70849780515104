/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import { Badge, Button, FlexCol, FlexRow, Icons, Tooltip } from 'sarsaparilla';
import moment from 'moment-timezone';
import { storeCampsiteClicked } from 'ui-camping/src/camping/utils/campground';
import {
    CAMPSITE_EARLY_ACCESS_AVAILABLE,
    CAMPSITE_PARTIAL_AVAILABLE,
    CAMPSITE_UNAVAILABLE,
    CAMPSITE_AVAILABLE,
    ENTER_DATES,
    PARTIALLY_AVAILABLE,
    FIRST_COME_FIRST_SERVED,
} from 'ui-camping/src/shared/constants/availability';
import { ACCESSIBLE_CAMPSITE_TOOLTIP } from 'ui-camping/src/shared/constants/campsite';
import { createComponent, handleClosePopUp } from './common';
import {
    AVAILABILITY_GRID,
    CAMPSITE_LIST,
} from 'ui-camping/src/shared/constants/campground';

export default function campingMapPopup(
    {
        imageUrl,
        title,
        location,
        attributes,
        targetUri,
        featureId,
        entityId,
        availabilityStatus,
        isItinerary,
        onClickFunction,
        accessible,
        campsite_type,
        isInternal,
        startDate,
        endDate,
        isSiteView,
        siteAvailability,
        gridStartDate,
        gridEndDate,
        gridAvailability,
        fullGridStartDate,
        fullGridEndDate,
        selectedTab,
        hasSelectedGridDates,
        calendarStartDate,
        calendarEndDate,
        isNSS,
    },
    deselectFeature
) {
    const isMobile = window.innerWidth < 768;
    const isEnterDates = availabilityStatus === ENTER_DATES;
    const isListTab = selectedTab === CAMPSITE_LIST;
    const isGridTab = selectedTab === AVAILABILITY_GRID;
    let campgroundId = null;

    if (featureId) {
        [campgroundId] = featureId.split('_');
    }

    const isAccessible =
        typeof accessible === 'string' ? JSON.parse(accessible) : accessible;
    let imgUrl = imageUrl === 'null' ? null : imageUrl;

    if (isSiteView && !!siteAvailability && !imgUrl) {
        imgUrl = `${process.env.CDN}/img/global/unavailable/image-unavailable.jpg`;
    }

    const siteAttributes =
        attributes && typeof attributes === 'string'
            ? JSON.parse(attributes)
            : attributes;
    const targetUrl = targetUri === 'null' ? null : targetUri;

    const formatDate = (date) => {
        return moment(date).utc().format('MM/DD/YY');
    };

    const getDateLabel = (start, end) => {
        if (!start || !end) return '';
        return `${formatDate(start)} to ${formatDate(end)}`;
    };

    const isAvailableSite = siteAvailability === CAMPSITE_AVAILABLE;
    const isAvailableGrid =
        isGridTab &&
        (gridAvailability === CAMPSITE_AVAILABLE || (gridStartDate && gridEndDate));
    const isPartialAvailableGrid =
        isGridTab &&
        gridAvailability === CAMPSITE_PARTIAL_AVAILABLE &&
        !hasSelectedGridDates;
    const isUnavailableGrid = isGridTab && gridAvailability === CAMPSITE_UNAVAILABLE;

    const fullGridDatesText = getDateLabel(fullGridStartDate, fullGridEndDate);
    const calendarDatesText = getDateLabel(calendarStartDate, calendarEndDate);
    const gridDatesText = getDateLabel(gridStartDate, gridEndDate);
    const datesText = getDateLabel(startDate, endDate);

    const hasCalendarDate = calendarStartDate && calendarEndDate;
    const isAvailableCalendar =
        hasCalendarDate &&
        [CAMPSITE_AVAILABLE, CAMPSITE_EARLY_ACCESS_AVAILABLE].includes(
            availabilityStatus
        );
    const isPartialAvailableCalendar =
        hasCalendarDate &&
        [PARTIALLY_AVAILABLE, FIRST_COME_FIRST_SERVED].includes(availabilityStatus);
    const isUnavailableCalendar = !isAvailableCalendar && !isPartialAvailableCalendar;

    const hasAvailability = siteAvailability === 'Available';
    const titleClassName = imgUrl ? '' : ' popup-title-no-image';
    const siteAvailabilityClassName = `map-popup-${hasAvailability ? '' : 'un'}availability-info`;
    const availability = (
        <div className={siteAvailabilityClassName}>{siteAvailability}</div>
    );

    const isAvailable =
        availabilityStatus === CAMPSITE_AVAILABLE ||
        availabilityStatus === CAMPSITE_EARLY_ACCESS_AVAILABLE ||
        isAvailableGrid ||
        (startDate && endDate) ||
        (gridStartDate && gridEndDate);

    const dateTextContent =
        gridDatesText ||
        ((isAvailableCalendar || isUnavailableCalendar) && calendarDatesText) ||
        ((isAvailableGrid || isUnavailableGrid) && fullGridDatesText);

    const getAvailabilitySection = () => {
        const isCalendarStyle =
            (!isListTab && hasCalendarDate && !hasSelectedGridDates) ||
            (isListTab && hasCalendarDate);

        const isUnavailable = isCalendarStyle ? isUnavailableCalendar : isUnavailableGrid;
        const isPartialAvailable = isCalendarStyle
            ? isPartialAvailableCalendar
            : isPartialAvailableGrid;

        return (
            <div className="popup-availability-container">
                <FlexCol>
                    <FlexRow>
                        {!isUnavailable && !isPartialAvailable && (
                            <span className="availability-grid-status-title status-available-grid">
                                {isListTab && !isEnterDates && availabilityStatus}
                                {isGridTab && 'Available'}
                            </span>
                        )}
                        {isPartialAvailable && (
                            <span className="availability-grid-status-title status-partially-available-grid">
                                {isListTab ? availabilityStatus : 'Partially available'}
                            </span>
                        )}
                        {isUnavailable && (
                            <span className="availability-grid-status-title status-unavailable-grid">
                                {isListTab ? availabilityStatus : 'Unavailable'}
                            </span>
                        )}
                    </FlexRow>
                    <FlexRow>
                        {isListTab && (isAvailable || isUnavailable) && (
                            <span>{dateTextContent}</span>
                        )}
                        {!isListTab &&
                            (isAvailable || isUnavailable) &&
                            !isPartialAvailable && <span>{dateTextContent}</span>}
                        {(!isListTab || isListTab) && isPartialAvailable && (
                            <span>Check grid for available dates</span>
                        )}
                        {isListTab && !hasCalendarDate && (
                            <span className="status-partially-available-list">
                                Enter dates for availability
                            </span>
                        )}
                    </FlexRow>
                </FlexCol>
            </div>
        );
    };

    const onClosePopup = () => {
        if (deselectFeature) deselectFeature();
        handleClosePopUp();
    };

    const popup = (
        <div className="map-popup">
            <div className="map-popup-message">
                <Button
                    className="close-pop-up-btn"
                    appearance="subtle"
                    size="sm"
                    shouldFitContainer={false}
                    onClick={onClosePopup}
                    iconBeforeElement={<Icons.IconClose />}
                    screenReaderTextBefore="Close the modal"
                    gaTrackingId="681762123001"
                />

                {imgUrl ? (
                    <div
                        aria-hidden="true"
                        className="map-popup-preview-image"
                        style={{ backgroundImage: `url("${imgUrl}")` }}
                    >
                        {isSiteView && !!siteAvailability && availability}
                    </div>
                ) : null}

                <div className={`map-popup-title${titleClassName}`}>{title}</div>

                {location ? <p className="map-popup-text">{location}</p> : null}

                {isAccessible || !!siteAttributes?.length ? (
                    <ul>
                        {isAccessible && (
                            <li className="accessible-icon-list">
                                <Tooltip content={ACCESSIBLE_CAMPSITE_TOOLTIP}>
                                    <Icons.IconAccessible
                                        size="sm"
                                        className="accessible-icon"
                                    />
                                </Tooltip>
                            </li>
                        )}
                        {isInternal && campsite_type ? <li>{campsite_type}</li> : null}
                        {siteAttributes
                            ? siteAttributes.map(({ key, value }) => {
                                  const hasAvailabilityAttribute = key === 'availability';
                                  if (hasAvailabilityAttribute) return null;
                                  return <li key={key}>{value}</li>;
                              })
                            : null}
                    </ul>
                ) : null}

                {(isAccessible || !!siteAttributes?.length) &&
                    !isSiteView &&
                    !isItinerary &&
                    !isNSS &&
                    !isInternal &&
                    getAvailabilitySection()}

                {((!isItinerary && targetUrl) || isAvailable) && !isInternal ? (
                    <div className="map-popup-button-wrap">
                        <div className="map-popup-buttons">
                            {!isItinerary && !isSiteView && targetUrl && (
                                <div>
                                    <Button
                                        className={isEnterDates ? 'align-right' : ''}
                                        href={targetUrl}
                                        appearance="link"
                                        onClick={() =>
                                            storeCampsiteClicked(campgroundId, entityId)
                                        }
                                        rel="noopener noreferrer"
                                        size="md"
                                        gaTrackingId="317756999568"
                                        gaTrackingValue={title}
                                    >
                                        View Details
                                    </Button>
                                    {featureId === '250860_583320' && (
                                        <Badge appearance="info">Portage required</Badge>
                                    )}
                                </div>
                            )}

                            {dateTextContent &&
                                (isAvailableCalendar ||
                                    isAvailableGrid ||
                                    isAvailableSite) && (
                                    <div>
                                        <Button
                                            id={`book-now-button-${entityId}`}
                                            className={`list-map-book-now-button-tracker ${
                                                isMobile ? 'mobile' : 'desktop'
                                            }`}
                                            onClick={() =>
                                                onClickFunction({
                                                    [entityId]: {
                                                        startDate:
                                                            startDate || gridStartDate,
                                                        endDate: endDate || gridEndDate,
                                                    },
                                                })
                                            }
                                            appearance="primary"
                                            size="xs"
                                            rel="noopener noreferrer"
                                            gaTrackingId="253459600897"
                                            gaTrackingValue={title}
                                        >
                                            Add to Cart
                                        </Button>
                                    </div>
                                )}
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );

    return createComponent(popup);
}
