/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import React from 'react';
import axios from 'axios';
import uniq from 'lodash/uniq';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    TextArea,
    Button,
    DividerNew as Divider,
    FlexRow,
    FlexCol,
    Label,
    useToast,
    useFlags,
    announce,
    LocalStorage,
} from '@fp/sarsaparilla';
import * as SearchActions from 'ui-search/src/actions/search';
import {
    ALL_FILTERS,
    ALL_FILTER_KEYS,
    SEARCH_FILTER_ACTIVITIES,
    CAMPSITE_AMENITY_ELECTRICAL_HOOKUP,
} from 'ui-search/src/constants';

const exampleOptions = [
    'Tent camping in Colorado with a waterfront view.',
    'Tickets to visit the Pearl Harbor National Memorial anytime next month.',
    'Camping with my 30 foot RV electric hookup in Colorado next weekend.',
    'Alaska cabins with any availability in July.',
    'Camping near Washington DC 6 people 2 large tents August 14-16.',
];

const coordinateKeys = [
    'lat',
    'long',
    'radius',
    'lat_sw',
    'lng_sw',
    'lat_ne',
    'lng_ne',
    'polygon',
];

const paramKeys = [
    'entity_id',
    'entity_type',
    'exact',
    'l',
    'location',
    'start_date',
    'end_date',
    'flex_start_range',
    'flex_end_range',
    'avail_months',
    'avail_nights',
    'tour_times',
    'sort',
    'start',
    'size',
    'include_unavailable',
    'include_notreservable',
    'include_partially_available',
];

export default function HeroAiSearch() {
    const { useNewCampingFilters } = useFlags();
    const [searchLoading, setSearchLoading] = React.useState(false);
    const [startSearch, setStartSearch] = React.useState(false);
    const [iaTerm, setIaTerm] = React.useState('');
    const [error, setError] = React.useState('');

    const dispatch = useDispatch();
    const searchStore = useSelector((state) => state.search);
    const uiFilters = useSelector((state) => state.search?.uiFilters);
    const showToast = useToast();
    const textAreaRef = React.useRef();

    const navigateToSearch = () => {
        const url = SearchActions.buildSearchNavUri('/search', { search: searchStore });
        window.location.assign(url);
        setTimeout(() => {
            setSearchLoading(false);
            setStartSearch(false);
        }, 0);
    };

    React.useEffect(() => {
        if (startSearch) {
            navigateToSearch();
        }
        // eslint-disable-next-line
    }, [startSearch]);

    const findReduxFilterObject = (params, searchParam) => {
        const correctFilter = params;
        const [label, data] = searchParam;
        let correct = null;
        for (const filter in correctFilter) {
            if (Object.prototype.hasOwnProperty.call(correctFilter, filter)) {
                const current = correctFilter[filter];
                if (current?.attribute === label && current?.filterValue === data) {
                    correct = correctFilter[filter];
                    break;
                }
                if (current?.attribute === label && current?.filterValue.includes(data)) {
                    correct = correctFilter[filter];
                    break;
                }
            }
        }
        return correct;
    };

    const findReduxFilterName = (label) => {
        let correct = null;
        for (const value in ALL_FILTER_KEYS) {
            if (Object.prototype.hasOwnProperty.call(ALL_FILTER_KEYS, value)) {
                const current = ALL_FILTER_KEYS[value];
                if (current.indexOf(label) !== -1) {
                    correct = value;
                    return value;
                }
            }
        }
        return correct;
    };

    const parseRegularFqParams = (value) => {
        const parsedParams = {};
        let name = '';
        const searchParam = value.split(':');
        const clearLabel = searchParam[0];
        for (const filter in ALL_FILTERS) {
            if (Object.prototype.hasOwnProperty.call(ALL_FILTERS, filter)) {
                const current = ALL_FILTERS[filter];
                if (current.filters) {
                    const filterValue = findReduxFilterObject(current.filters, [
                        clearLabel,
                        searchParam[1],
                    ]);
                    const filterObject = findReduxFilterName(clearLabel);
                    if (filterValue) {
                        name = filterObject;
                        parsedParams[filterObject] = {
                            ...parsedParams[filterObject],
                            [current.key]: current,
                        };
                        break;
                    }
                }
            }
        }
        return [name, parsedParams];
    };

    const parseAssetActivities = (value) => {
        const [key, data] = value.split(':');
        let label = '';
        const filterObject = {
            key: '',
            value: '',
            filters: [
                {
                    attribute: key,
                    filterValue: '',
                },
            ],
        };
        for (const activity in SEARCH_FILTER_ACTIVITIES) {
            if (
                Object.prototype.hasOwnProperty.call(SEARCH_FILTER_ACTIVITIES, activity)
            ) {
                const currentActivity = SEARCH_FILTER_ACTIVITIES[activity];
                if (currentActivity.value?.toLowerCase() === data?.toLowerCase()) {
                    label = currentActivity.key;
                    filterObject.key = label;
                    filterObject.value = currentActivity.value;
                    filterObject.filters[0].filterValue = data;
                    break;
                }
            }
        }
        return [label, filterObject];
    };

    const parseFqAmountParams = (value, sign) => {
        const [key, data] = value.split(sign);
        const name = findReduxFilterName(key);
        const filterObject = {
            key: name,
            value: `${sign}${data}`,
            filters: [
                {
                    attribute: key,
                    filterValue: `${sign}${data}`,
                },
            ],
        };
        return [name, filterObject];
    };

    const parseTourTimes = (times) => {
        let filters = {};
        for (const time in times) {
            if (Object.prototype.hasOwnProperty.call(times, time)) {
                const value = `tour_times:${times[time]}`;
                const filter = parseRegularFqParams(value);
                if (filter[1]?.tourTimes) {
                    filters = { ...filters, ...filter[1]?.tourTimes };
                }
            }
        }
        return filters;
    };

    const parseFqParams = (params) => {
        let parsedParams = { activities: {} };
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const value = params[key];
                if (value.includes('>=')) {
                    const [label, newFilters] = parseFqAmountParams(value, '>=');
                    parsedParams[label] = {
                        ...parsedParams[label],
                        [label]: newFilters,
                    };
                    continue;
                }
                if (value.includes('<=')) {
                    const [label, newFilters] = parseFqAmountParams(value, '<=');
                    parsedParams[label] = {
                        ...parsedParams[label],
                        [label]: newFilters,
                    };
                    continue;
                }
                if (value.includes('asset_activities')) {
                    const [label, newActivityParam] = parseAssetActivities(value);
                    parsedParams.activities = {
                        ...parsedParams.activities,
                        [label]: newActivityParam,
                    };
                    continue;
                } else {
                    const [name, newFilters] = parseRegularFqParams(value);
                    parsedParams = {
                        ...parsedParams,
                        [name]: { ...parsedParams[name], ...newFilters[name] },
                    };
                }
            }
        }
        return parsedParams;
    };

    const parseFgParams = (params) => {
        const values = [];
        if (params.electric_amp) {
            values.push(`${params.electric_amp}-amp`);
        }
        if (params.groupsize) {
            values.push(`group-size:${params.groupsize}`);
        }
        if (params.electric_hookup) {
            values.push('electricity-hookup');
        }
        if (params.sewer_hookup) {
            values.push('sewer-hookup');
        }
        if (params.water_hookup) {
            values.push('water-hookup');
            values.push('showers');
        }
        if (params.waterfront) {
            values.push('waterfront');
        }
        if (params.length) {
            values.push(`vehicle-length:${params.length}`);
        }
        if (params.sitetypes) {
            params.sitetypes.forEach((item) => {
                const value = item.toLowerCase();
                if (value.includes('tent')) {
                    values.push('tent');
                }
                if (value.includes('rv') || value.includes('trailer')) {
                    values.push('rmt');
                }
                if (value.includes('lookout') || value.includes('yurt')) {
                    values.push('lodging');
                }
            });
        }
        return uniq(values);
    };

    const getParsedCriterias = (v) => {
        const params = { hasFilters: true };
        params.sort = 'available';

        if (v.fq) {
            const newUiFilters = parseFqParams(uniq(v.fq));
            params.uiFilters = { ...uiFilters, ...newUiFilters };
            if (v.parsed.electric_hookup) {
                params.uiFilters.campsiteAmenities = {
                    ...params?.uiFilters?.campsiteAmenities,
                    att_electricity_hookup: CAMPSITE_AMENITY_ELECTRICAL_HOOKUP,
                };
            }

            if (useNewCampingFilters) {
                params.fg = parseFgParams(v.parsed);
            }
        }

        for (const key of paramKeys) {
            if (v.hasOwnProperty(key)) {
                if (key === 'start_date' && v.end_date) {
                    params.checkin_time = moment(v[key]).utc().format('MM/DD/YYYY');
                    continue;
                }
                if (key === 'end_date' && v.start_date) {
                    params.checkout_time = moment(v[key]).utc().format('MM/DD/YYYY');
                    continue;
                }
                if (key === 'location') {
                    params.what = v[key];
                    continue;
                }
                if (key === 'tour_times') {
                    const tourTimes = parseTourTimes(v[key]);
                    params.uiFilters = { ...params.uiFilters, tourTimes };
                } else {
                    params[key] = v[key];
                }
            }
        }

        for (const key of coordinateKeys) {
            if (v.hasOwnProperty(key)) {
                if (v[key] === 0) {
                    continue;
                }
                if (key === 'lat_sw') {
                    params.latSw = v[key];
                }
                if (key === 'lng_sw') {
                    params.lngSw = v[key];
                }
                if (key === 'lat_ne') {
                    params.latNe = v[key];
                }
                if (key === 'lng_ne') {
                    params.lngNe = v[key];
                } else {
                    params[key] = v[key];
                }
            }
        }

        return params;
    };

    const triggerTextAreaError = () => {
        const message = 'Please add a search query';
        setError(message);
        const hasTextAreaRef = textAreaRef?.current?.focus;
        if (hasTextAreaRef) {
            textAreaRef.current.focus();
            announce(message);
        }
    };

    const submitSearch = async (value) => {
        const term = !value ? iaTerm : value;
        if (!term) {
            triggerTextAreaError();
            return;
        }
        setSearchLoading(true);
        try {
            dispatch(SearchActions.resetSearchFilters());
            const { data } = await axios.get(`${process.env.API}/search/parse?q=${term}`);
            const params = getParsedCriterias(data);
            dispatch(SearchActions.updateSearchCriterias(params));
            setStartSearch(true);
            LocalStorage.setItem('ai-survey', true);
        } catch {
            setSearchLoading(false);
            showToast('There was an error, try again later.', {
                type: 'error',
                delay: 5000,
            });
        }
    };

    const onChange = (e) => {
        e.preventDefault();
        setIaTerm(e.target?.value);
        if (error) {
            setError('');
        }
    };

    const onExampleSelect = (v) => {
        setIaTerm(v);
        if (error) {
            setError('');
        }
        submitSearch(v);
    };

    return (
        <>
            <div className="nav-search-input-wrapper nav-search-input-area-container ">
                <TextArea
                    className="nav-search-input-area"
                    placeholder="Tell us what kind of outdoor recreation you want to reserve. Be as descriptive as possible."
                    id="navigation-ia-area"
                    isLabelVisible={false}
                    value={iaTerm}
                    onChange={onChange}
                    innerRef={textAreaRef}
                    errorText={error}
                    isInvalid={error}
                />
                <Button
                    className="nav-search-button"
                    clickTagCategory="Homepage"
                    clickTagAction={`AI Search - ${iaTerm}`}
                    clickTagLabel="Hero"
                    isLoading={searchLoading}
                    onClick={() => submitSearch()}
                >
                    SEARCH
                </Button>
            </div>

            <div>
                <Divider className="mt-3 mb-2" />
                <FlexRow
                    className="pb-2"
                    hasGutters={false}
                    justifyContent="space-between"
                >
                    <FlexCol>
                        <Label>Example Searches:</Label>
                    </FlexCol>
                </FlexRow>
                <FlexRow
                    alignItems="stretch"
                    hasGutters={false}
                    className="nav-search-example-boxes"
                >
                    {exampleOptions.map((item) => (
                        <FlexCol>
                            <Button
                                onClick={() => onExampleSelect(item)}
                                isDisabled={searchLoading}
                                size="sm"
                                isUnstyled
                                clickTagCategory="Homepage"
                                clickTagAction={`AI Search - ${item}`}
                                clickTagLabel="Hero"
                            >
                                {item}
                            </Button>
                        </FlexCol>
                    ))}
                </FlexRow>
            </div>
        </>
    );
}
