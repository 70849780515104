/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

/*
 * HeroContainer.jsx
 * Created by Destin frasier on 05/10/21
 * Updated on 09/21/21
 */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { get, isEmpty, throttle } from 'lodash';
import moment from 'moment';
import {
    SearchActions,
    SearchInputContainer,
    Heading,
    Icons,
    FlexRow,
    FlexCol,
    TabsNew,
    TabPanel,
    DropdownMenu,
    Button,
    SessionStorage,
    Radio,
    RadioGroup,
    MapHelper,
    LocalStorage,
    Divider,
    Text,
    Badge,
    momentToCalendarDate,
    calendarDateToMoment,
    DatePicker,
    withLDConsumer,
    FeatureFlag,
} from 'sarsaparilla';
import { connect } from 'react-redux';
import { getGatewayUrl } from 'shared-ui/utils/getGatewayUrl';
import { HOMEPAGE_HERO_INVENTORY } from 'site-kit';
import TourTimeFilter from 'ui-navigation/src/containers/homepage/TourTimeFilter';
import { PhotoInfoFlyout } from '../../components/homepage/hero/PhotoInfoFlyout';
import HeroAiSearch from '../../components/homepage/hero/HeroAiSearch';
import { SEARCH } from '../../constants/propTypes.js';
import AccessibleCheckbox from '../../components/sharedComponents/AccessibleCheckbox';
import FlexDates from '../../components/homepage/FlexDates/FlexDatesMain';
import { CAMPSITE_ACCESSIBLE_FILTER as accessibilityFilter } from '../../../../ui-search/src/constants/index.js';
import ExploreAllQuickPromos from './ExploreAllQuickPromos';
import CampingLodgingCarousel from './heroCarousels/CampingLodgingCarousel';
import TicketsToursCarousel from './heroCarousels/TicketsToursCarousel';
import PermitsCarousel from './heroCarousels/PermitsCarousel';
import DayUseVenuesCarousel from './heroCarousels/DayUseVenueCarousel';
import MobileSearch from './MobileSearch';
import { isWithinUS } from '../../utils/stateHelper';
import * as searchActions from '../../../../ui-search/src/actions/search';

// Hero Image Info
const heroImages = [
    {
        heroBgImg: 'hero-01',
        locationName: 'Great Smoky Mountains National Park, TN',
        locationLink: getGatewayUrl('2739'),
        photoCredit: 'Norman Lathrop (Share the Experience)',
        altText:
            'Palmer Chapel surrounded by fall foliage within Great Smoky Mountains National Park, TN',
    },
    {
        heroBgImg: 'hero-02',
        locationName: 'Denali National Park & Preserve, AK',
        locationLink: getGatewayUrl('2658'),
        photoCredit: 'Jeremy Nickoson (Share the Experience)',
        altText: 'The vast landscape of Denali National Park & Preserve, AK',
    },
    {
        heroBgImg: 'hero-03',
        locationName: 'White River National Forest, CO',
        locationLink: getGatewayUrl('1055'),
        photoCredit: 'Shandra Mondragon (Share the Experience)',
        altText: 'Animals grazing within White River National Forest, CO',
    },
    {
        heroBgImg: 'hero-04',
        locationName:
            'Pike and San Isabel National Forests & Cimarron and Comanche National Grasslands, CO',
        locationLink: getGatewayUrl('1053'),
        photoCredit: 'Sean Asmussen (Share the Experience)',
        altText:
            'A path surrounded by fall foliage within Pike and San Isabel National Forests & Cimarron and Comanche National Grasslands, CO',
    },
    {
        heroBgImg: 'hero-05',
        locationName: 'Denali National Park & Preserve, AK',
        locationLink: getGatewayUrl('2658'),
        photoCredit: 'David Wang (Share the Experience)',
        altText:
            'Caribou roaming a hillside within the vast landscape of Denali National Park & Preserve, AK',
    },
];

const SEARCH_CAL_DATE_FORMAT = 'MM/DD/YYYY';

const TENT_FILTER = {
    key: 'campsite-equipment-tent',
    value: 'Tent',
    filters: [
        {
            attribute: 'campsite_equipment_name',
            filterValue: 'Tent',
        },
    ],
};

const RV_FILTER = {
    key: 'campsite-equipment-rv',
    value: 'RV/Motorhome',
    filters: [
        {
            attribute: 'campsite_equipment_name',
            filterValue: 'RV,RV/Motorhome',
        },
    ],
};

const CABIN_FILTER = {
    key: 'campsite-type-cabin',
    value: 'Cabin',
    filters: [
        {
            attribute: 'campsite_type',
            filterValue: 'CABIN ELECTRIC,CABIN NONELECTRIC',
        },
    ],
};

const LOOKOUT_FILTER = {
    key: 'campsite-type-lookout',
    value: 'Lookout',
    filters: [
        {
            attribute: 'campsite_type',
            filterValue: 'LOOKOUT',
        },
    ],
};

const YURTS_FILTER = {
    key: 'campsite-type-yurts',
    value: 'Yurts',
    filters: [
        {
            attribute: 'campsite_type',
            filterValue: 'YURT',
        },
    ],
};

const tabPanelGACategory = 'Homepage';
const tabPanelGALabel = 'Hero Search';

export class HeroContainer extends React.Component {
    static propTypes = {
        search: SEARCH,
        dispatch: PropTypes.func.isRequired,
        submitSimpleSearch: PropTypes.func,
        flags: PropTypes.shape({
            availableThisWeekend: PropTypes.bool,
            useIaSearchParse: PropTypes.bool,
            useNewCampingFilters: PropTypes.bool,
        }),
    };

    constructor(props) {
        super(props);
        let startDate = null;
        const flexDates = {};

        if (props.search.checkin_time) {
            startDate = moment(props.search.checkin_time, SEARCH_CAL_DATE_FORMAT);
            flexDates.startDate = moment(
                props.search.checkin_time,
                SEARCH_CAL_DATE_FORMAT
            );
        }
        let endDate = null;
        if (props.search.checkout_time) {
            endDate = moment(props.search.checkout_time, SEARCH_CAL_DATE_FORMAT);
            flexDates.endDate = moment(
                props.search.checkout_time,
                SEARCH_CAL_DATE_FORMAT
            );
        }
        if (props.search.avail_nights) {
            flexDates.duration = props.search.avail_nights;
        }
        if (props.search.flex_start_range) {
            flexDates.startRange = props.search.flex_start_range;
        }
        if (props.search.flex_end_range) {
            flexDates.endRange = props.search.flex_end_range;
        }
        if (props?.search?.avail_months) {
            flexDates.months = props?.search?.avail_months?.map((item) =>
                moment(item).utc().format('MM-YYYY')
            );
        }
        const activeSearchTabIndex = Number(
            SessionStorage.getItem('home-active-search-tab-index') || 0
        );

        const waysToStay = 'All';

        this.state = {
            photoFlyout: false,
            useMobile: false,
            searchPlaceholder:
                'Search for campsites, tours, and other recreation activities...',
            searchPlaceholderCamping: 'Search by location or campground name',
            startDate,
            endDate,
            activeSearchTabIndex,
            waysToStay,
            searchLoading: false,
            campingTabCarouselData: this.nearbyLocationsShape(),
            ticketsTabCarouselData: this.nearbyLocationsShape(),
            permitsTabCarouselData: this.nearbyLocationsShape(),
            dayUseVenuesTabCarouselData: this.nearbyLocationsShape(),
            flexDatesFilter: flexDates,
            geoIp: null,
            tourTimeFilter: {
                earlyMorning: false,
                morning: false,
                earlyAfternoon: false,
                afternoon: false,
                evening: false,
                lateNight: false,
            },
        };

        this.measurePage = throttle(this.measurePage.bind(this), 50);
        this.randomHeroImage = heroImages[Math.floor(Math.random() * heroImages.length)];
    }

    componentDidMount() {
        const { dispatch } = this.props;

        dispatch(
            SearchActions.updateSearchCriterias({
                what: '',
                headerTextQuery: '',
            })
        );

        SearchActions.fetchUserGeoIp()
            .then((geoIp) => {
                this.setState({
                    geoIp,
                });
            })
            .catch(() => {
                // This code commented out because it is not valid
                // this.setState({
                //     [stateKey]: {
                //         ...stateObjectByKey,
                //         hasErrors: true,
                //     },
                // });
            });

        window.addEventListener('pageshow', this.pageShowListener);
        window.addEventListener('resize', this.measurePage);
        this.measurePage();
        window.addEventListener('keydown', this.handleKeyDown);
        dispatch(
            SearchActions.updateSearchCriterias({
                selectedSuggestion: null,
            })
        );
    }

    // eslint-disable-next-line
    componentDidUpdate(prevProps, prevState) {
        if (this.state.geoIp !== prevState.geoIp) {
            this.handleGetAvailableThisWeekend();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.measurePage);
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('scroll', this.closePhotoCredit);
        window.removeEventListener('pageshow', this.pageShowListener);
    }

    nearbyLocationsShape = () => {
        return {
            locations: [],
            isLoading: true,
            hasErrors: false,
        };
    };

    pageShowListener = (event) => {
        if (event.persisted) {
            this.setState({ searchLoading: false });
        }
    };

    generateWeekendDates = (start, end) => {
        const isSunday = moment().day() === 0;
        let startDate = null;
        let endDate = null;
        if (isSunday) {
            startDate = moment().add(1, 'week').startOf('week').add(start, 'days');
            endDate = moment().add(1, 'week').startOf('week').add(end, 'days');
        } else {
            startDate = moment().startOf('week').add(start, 'days');
            endDate = moment().startOf('week').add(end, 'days');
        }

        if (startDate.isSame(moment(), 'day')) {
            startDate = moment().startOf('day');
        }
        if (endDate.isSame(moment(), 'day')) {
            endDate = moment().startOf('day');
        }
        return [startDate, endDate];
    };

    fetchNearbyLocations = (
        stateKey,
        inventoryTypes = [],
        weekendRange,
        radius = 300
    ) => {
        const { windowWidth, [stateKey]: stateObjectByKey } = this.state;
        let { searchAcrossUS } = process.env.HOMEPAGE_OPTIONS;
        let { bounds } = process.env.SEARCH;
        if (this.props?.flags?.availableThisWeekend) {
            searchAcrossUS = false;
        }
        if (!searchAcrossUS && !bounds) {
            // default to search across us for some inventory types
            searchAcrossUS =
                inventoryTypes.length > 0 &&
                (inventoryTypes[0] === 'permits' ||
                    inventoryTypes[0] === 'tours' ||
                    inventoryTypes[0] === 'tickettours' ||
                    inventoryTypes[0] === 'timedentry_tours');
        }

        let startDate = null;
        let endDate = null;

        if (weekendRange) {
            const dateFormat = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
            const [start, end] = this.generateWeekendDates(...weekendRange);
            startDate = start.format(dateFormat);
            endDate = end.format(dateFormat);
        }

        const geoipResult = this.state.geoIp;
        const userIsWithinUS = isWithinUS(geoipResult.latitude, geoipResult.longitude);

        if (!userIsWithinUS && !bounds) {
            bounds = {
                lat_sw: 32.5341,
                lng_sw: -124.482,
                lat_ne: 42.0095,
                lng_ne: -114.1315,
            };
        }

        searchActions
            .fetchNearbyInventoryLocations(
                geoipResult.latitude,
                geoipResult.longitude,
                inventoryTypes,
                19,
                searchAcrossUS,
                'available',
                [],
                0,
                [],
                `${geoipResult.city}, ${geoipResult.region_name}`,
                bounds,
                startDate,
                endDate,
                true,
                radius
            )
            .then((result) => {
                if (
                    (!result.data.results && radius < 1200) ||
                    (result.data.results &&
                        result.data.results.length <= 4 &&
                        radius < 1200)
                ) {
                    this.fetchNearbyLocations(
                        stateKey,
                        inventoryTypes,
                        weekendRange,
                        radius * 2
                    );
                    return;
                }

                // If the width of the viewport is greater than 1123px show
                // 19 results so that the carousel is full.
                const amountOfCards =
                    windowWidth > 1123
                        ? result.data.results.slice(0, 19)
                        : result.data.results.slice(0, 17);

                this.setState({
                    [stateKey]: {
                        ...stateObjectByKey,
                        locations: amountOfCards,
                        isLoading: false,
                    },
                });
            })
            .catch(() => {
                this.setState({
                    [stateKey]: {
                        ...stateObjectByKey,
                        hasErrors: true,
                    },
                });
            });
    };

    fetchCampingNearbyLocations = () => {
        this.fetchNearbyLocations('campingTabCarouselData', ['camping'], [5, 7]);
    };

    fetchTicketsNearbyLocations = () => {
        this.fetchNearbyLocations(
            'ticketsTabCarouselData',
            ['tickettours', 'activitypass'],
            [6, 7]
        );
    };

    fetchPermitsNearbyLocations = () => {
        this.fetchNearbyLocations('permitsTabCarouselData', ['permits']);
    };

    fetchDayUseVenuesNearbyLocations = () => {
        this.fetchNearbyLocations(
            'dayUseVenuesTabCarouselData',
            ['dayuse', 'venuereservations'],
            [6, 7]
        );
    };

    onSingleDateChange = (date) => {
        const datePickerDate = calendarDateToMoment(date);
        this.setState({
            startDate: datePickerDate,
            endDate: datePickerDate,
        });
    };

    onTourTimeChange = (tourTimeFilter) => {
        this.setState({ tourTimeFilter });
    };

    openPhotoCredit = () => {
        document.addEventListener('scroll', this.closePhotoCredit);

        this.setState({
            photoFlyout: true,
        });
    };

    closePhotoCredit = () => {
        document.removeEventListener('scroll', this.closePhotoCredit);

        this.setState({
            photoFlyout: false,
        });
    };

    measurePage = () => {
        const { windowWidth: stateWindowWidth } = this.state;
        const windowWidth = window.innerWidth;
        const isMobile = windowWidth < 768;

        /**
         * As we are displaying a different search on mobile, we ensure to
         * restore desktop container to its original state
         */
        if (isMobile) {
            this.closePhotoCredit();
        }

        if (stateWindowWidth !== windowWidth) {
            this.setState({
                windowWidth,
            });
        }

        this.setState({ useMobile: isMobile });
    };

    submitSearch = (e) => {
        try {
            e.preventDefault();

            const { search } = this.props;

            this.setState({ searchLoading: true });

            this.runSearch(search.what, search.selectedSuggestion);
        } catch (err) {
            this.setState({ searchLoading: false });
        }
        return false;
    };

    parseFlexDatesParams = (months, duration) => {
        let monthsArray = [];
        if (months) {
            months.map((item) => {
                const av = moment(item, 'MM-YYYY')
                    .startOf('months')
                    .format('YYYY-MM-DD[T]HH:mm:ss[Z]');
                monthsArray = [...monthsArray, av];
                return item;
            });
        }
        return { avail_months: monthsArray, avail_nights: duration };
    };

    applyCampingUIFilters = () => {
        const { search, dispatch } = this.props;
        const { startDate, endDate, flexDatesFilter, waysToStay } = this.state;
        const isAccessibleChecked =
            search?.uiFilters?.campsiteAmenities?.hasOwnProperty(
                accessibilityFilter.key
            ) || false;

        dispatch(SearchActions.resetSearchFilters());

        let startDateStr = null;
        if (startDate) {
            startDateStr = moment(startDate, SEARCH_CAL_DATE_FORMAT)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }
        let endDateStr = null;
        if (endDate) {
            endDateStr = moment(endDate, SEARCH_CAL_DATE_FORMAT)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }

        if (flexDatesFilter?.startDate && flexDatesFilter.endDate) {
            startDateStr = moment(flexDatesFilter?.startDate, SEARCH_CAL_DATE_FORMAT)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
            endDateStr = moment(flexDatesFilter?.endDate, SEARCH_CAL_DATE_FORMAT)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }
        let startRange = null;
        if (flexDatesFilter.startRange) {
            startRange = flexDatesFilter.startRange;
        }

        let endRange = null;
        if (flexDatesFilter.endRange) {
            endRange = flexDatesFilter.endRange;
        }
        const { avail_months, avail_nights } = this.parseFlexDatesParams(
            flexDatesFilter?.months,
            flexDatesFilter?.duration
        );

        if (avail_months && avail_nights) {
            startDateStr = '';
            endDateStr = '';
        }
        let avMonths = avail_months;
        let avNights = avail_nights;
        if (isEmpty(flexDatesFilter)) {
            startDateStr = '';
            endDateStr = '';
            avMonths = [];
            avNights = '';
            endRange = '';
            startRange = '';
        }
        dispatch(
            SearchActions.updateSearchCriterias({
                checkin_time: startDateStr,
                checkout_time: endDateStr,
                flex_start_range: startRange,
                flex_end_range: endRange,
                avail_nights: avNights || '',
                avail_months: avMonths || [],
                sort: 'available',
            })
        );

        const uiFilters = {
            inventoryTypes: HOMEPAGE_HERO_INVENTORY.CAMPING_TAB,
        };

        if (this.props.flags?.useNewCampingFilters) {
            const fgParams = get(search, 'fg', []);
            if (waysToStay === 'Tent Camping') {
                dispatch(SearchActions.updateSearchCriteria('fg', [...fgParams, 'tent']));
            }

            if (waysToStay === 'RV/Motorhome/Trailer Camping') {
                dispatch(SearchActions.updateSearchCriteria('fg', [...fgParams, 'rmt']));
            }

            if (waysToStay === 'Lodging (Cabins/Yurts/Lookouts)') {
                dispatch(
                    SearchActions.updateSearchCriteria('fg', [...fgParams, 'lodging'])
                );
            }
        }
        if (!this.props.flags?.useNewCampingFilters) {
            if (waysToStay === 'Tent Camping') {
                uiFilters.campsiteEquipment = {
                    'campsite-equipment-tent': TENT_FILTER,
                };
            }

            if (waysToStay === 'RV Camping') {
                uiFilters.campsiteEquipment = {
                    'campsite-equipment-rv': RV_FILTER,
                };
            }

            if (waysToStay === 'Cabin / Yurts') {
                uiFilters.campsiteTypes = {
                    'campsite-type-cabin': CABIN_FILTER,
                    'campsite-type-lookout': LOOKOUT_FILTER,
                    'campsite-type-yurts': YURTS_FILTER,
                };
            }
        }

        if (isAccessibleChecked) {
            uiFilters.campsiteAmenities = {};
            uiFilters.campsiteAmenities[accessibilityFilter.key] = accessibilityFilter;
        }

        if (uiFilters) {
            Object.keys(uiFilters).forEach((key) => {
                Object.keys(uiFilters[key]).forEach((fltKey) => {
                    dispatch(SearchActions.addSearchFilter(key, uiFilters[key][fltKey]));
                });
            });
        }

        return uiFilters;
    };

    applyTicketsUIFilters = () => {
        const { dispatch } = this.props;
        const { startDate, endDate } = this.state;

        dispatch(SearchActions.resetSearchFilters());

        let startDateStr = null;
        if (startDate) {
            startDateStr = moment(startDate, SEARCH_CAL_DATE_FORMAT)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }
        let endDateStr = null;
        if (endDate) {
            endDateStr = moment(endDate, SEARCH_CAL_DATE_FORMAT)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }

        dispatch(
            SearchActions.updateSearchCriterias({
                checkin_time: startDateStr,
                checkout_time: endDateStr,
                sort: 'available',
            })
        );

        const uiFilters = {
            inventoryTypes: HOMEPAGE_HERO_INVENTORY.TICKETS_TOURS_TAB,
        };

        uiFilters.tourTimes = {};

        const { tourTimeFilter } = this.state;

        if (tourTimeFilter.earlyMorning) {
            uiFilters.tourTimes['tour-times-early-morning'] = {
                key: 'tour-times-early-morning',
                value: 'Early Morning (Midnight - 7:59 AM)',
                filters: [
                    {
                        attribute: 'tour_times',
                        filterValue: 'early_morning',
                    },
                ],
            };
        }

        if (tourTimeFilter.morning) {
            uiFilters.tourTimes['tour-times-morning'] = {
                key: 'tour-times-morning',
                value: 'Morning (8 - 11:59 AM)',
                filters: [
                    {
                        attribute: 'tour_times',
                        filterValue: 'morning',
                    },
                ],
            };
        }

        if (tourTimeFilter.earlyAfternoon) {
            uiFilters.tourTimes['tour-times-early-afternoon'] = {
                key: 'tour-times-early-afternoon',
                value: 'Early Afternoon (12 - 2:59 PM)',
                filters: [
                    {
                        attribute: 'tour_times',
                        filterValue: 'early_afternoon',
                    },
                ],
            };
        }

        if (tourTimeFilter.afternoon) {
            uiFilters.tourTimes['tour-times-afternoon'] = {
                key: 'tour-times-afternoon',
                value: 'Afternoon (3 - 5:59 PM)',
                filters: [
                    {
                        attribute: 'tour_times',
                        filterValue: 'afternoon',
                    },
                ],
            };
        }

        if (tourTimeFilter.evening) {
            uiFilters.tourTimes['tour-times-evening'] = {
                key: 'tour-times-evening',
                value: 'Evening (6 - 8:59 PM)',
                filters: [
                    {
                        attribute: 'tour_times',
                        filterValue: 'evening',
                    },
                ],
            };
        }

        if (tourTimeFilter.lateNight) {
            uiFilters.tourTimes['tour-times-late-night'] = {
                key: 'tour-times-late-night',
                value: 'Late Night (9 - 11:59 PM)',
                filters: [
                    {
                        attribute: 'tour_times',
                        filterValue: 'late_night',
                    },
                ],
            };
        }

        if (uiFilters) {
            Object.keys(uiFilters).forEach((key) => {
                Object.keys(uiFilters[key]).forEach((fltKey) => {
                    dispatch(SearchActions.addSearchFilter(key, uiFilters[key][fltKey]));
                });
            });
        }

        return uiFilters;
    };

    buildTourTimeFilter = () => {
        const tourTimeFilter = [];
        const ttFilter = this.state.tourTimeFilter;
        if (ttFilter) {
            if (ttFilter.earlyMorning) {
                tourTimeFilter.push('early_morning');
            }
            if (ttFilter.morning) {
                tourTimeFilter.push('morning');
            }
            if (ttFilter.earlyAfternoon) {
                tourTimeFilter.push('early_afternoon');
            }
            if (ttFilter.afternoon) {
                tourTimeFilter.push('afternoon');
            }
            if (ttFilter.evening) {
                tourTimeFilter.push('evening');
            }
            if (ttFilter.lateNight) {
                tourTimeFilter.push('late_night');
            }
        }
        return tourTimeFilter;
    };

    applyPermitsUIFilters = () => {
        const { dispatch } = this.props;

        dispatch(SearchActions.resetSearchFilters());

        dispatch(
            SearchActions.updateSearchCriterias({
                sort: 'available',
            })
        );

        const uiFilters = {
            inventoryTypes: HOMEPAGE_HERO_INVENTORY.PERMITS_TAB,
        };

        if (uiFilters) {
            Object.keys(uiFilters).forEach((key) => {
                Object.keys(uiFilters[key]).forEach((fltKey) => {
                    dispatch(SearchActions.addSearchFilter(key, uiFilters[key][fltKey]));
                });
            });
        }

        return uiFilters;
    };

    applyDayUseVenuesUiFilters = () => {
        const { dispatch } = this.props;
        const { startDate, endDate } = this.state;

        dispatch(SearchActions.resetSearchFilters());

        let startDateStr = null;
        if (startDate) {
            startDateStr = moment(startDate, SEARCH_CAL_DATE_FORMAT)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }
        let endDateStr = null;
        if (endDate) {
            endDateStr = moment(endDate, SEARCH_CAL_DATE_FORMAT)
                .startOf('day')
                .format(SEARCH_CAL_DATE_FORMAT);
        }

        dispatch(
            SearchActions.updateSearchCriterias({
                checkin_time: startDateStr,
                checkout_time: endDateStr,
                sort: 'available',
            })
        );

        const uiFilters = {
            inventoryTypes: HOMEPAGE_HERO_INVENTORY.DAY_USE_VENUES_TAB,
        };

        if (uiFilters) {
            Object.keys(uiFilters).forEach((key) => {
                Object.keys(uiFilters[key]).forEach((fltKey) => {
                    dispatch(SearchActions.addSearchFilter(key, uiFilters[key][fltKey]));
                });
            });
        }

        return uiFilters;
    };

    getTabUIFilters = (tab) => {
        let filters;

        if (tab === 'Camping') {
            filters = this.applyCampingUIFilters();
        }

        if (tab === 'Tickets') {
            filters = this.applyTicketsUIFilters();
        }

        if (tab === 'Permits') {
            filters = this.applyPermitsUIFilters();
        }

        if (tab === 'Day Use / Venues') {
            filters = this.applyDayUseVenuesUiFilters();
        }

        return filters;
    };

    showMainSearchResults = (tab = '') => {
        const { dispatch, search } = this.props;
        try {
            const uiFilters = this.getTabUIFilters(tab);

            dispatch(
                searchActions.submitSearchFromNavigation(
                    search.what,
                    search.selectedSuggestion,
                    uiFilters
                )
            );
        } catch (err) {
            this.setState({ searchLoading: false });
        }
        return false;
    };

    submitCampingSearch = () => {
        const { search } = this.props;
        const { flexDatesFilter } = this.state;

        try {
            const suggestion = search.selectedSuggestion || {
                text: search.what,
            };
            const uiFilters = this.applyCampingUIFilters();

            this.setState({ searchLoading: true });

            const { avail_months, avail_nights } = this.parseFlexDatesParams(
                flexDatesFilter?.months,
                flexDatesFilter?.duration
            );

            if (avail_nights && avail_months?.length) {
                suggestion.avail_nights = avail_nights;
                suggestion.avail_months = avail_months;
            } else {
                suggestion.checkin_time = flexDatesFilter.startDate;
                suggestion.checkout_time = flexDatesFilter.endDate;
                if (flexDatesFilter.startRange) {
                    suggestion.flex_start_range = flexDatesFilter.startRange;
                }
                if (flexDatesFilter.endRange) {
                    suggestion.flex_end_range = flexDatesFilter.endRange;
                }
            }

            suggestion.uiFilters = uiFilters;
            SearchActions.addSearchHistoryItem({
                rawSearchText: search.what,
                suggestion,
            });

            this.showMainSearchResults('Camping');
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('error', err);

            this.setState({ searchLoading: false });
        }
    };

    runSearch = (searchText, suggestion) => {
        const { dispatch, search } = this.props;

        const isAccessibleChecked =
            search?.uiFilters?.campsiteAmenities?.hasOwnProperty(
                accessibilityFilter.key
            ) || false;

        const uiFilters = {};
        if (isAccessibleChecked) {
            uiFilters.campsiteAmenities = {
                ...(search?.uiFilters?.campsiteAmenities || {}),
            };
            uiFilters.inventoryTypes = { ...HOMEPAGE_HERO_INVENTORY.CAMPING_TAB };
        }

        dispatch(
            searchActions.submitSimpleSearch(
                searchText,
                null,
                suggestion,
                null,
                uiFilters,
                false,
                null,
                null,
                null,
                false,
                search.fg ?? []
            )
        );
    };

    handleGetAvailableThisWeekend = (value) => {
        const {
            activeSearchTabIndex,
            campingTabCarouselData,
            ticketsTabCarouselData,
            permitsTabCarouselData,
            dayUseVenuesTabCarouselData,
        } = this.state;

        const currentIndex = !value ? activeSearchTabIndex : value;

        if (
            currentIndex === 1 &&
            campingTabCarouselData?.locations?.length === 0 &&
            campingTabCarouselData?.isLoading
        ) {
            this.fetchCampingNearbyLocations();
        }
        if (
            currentIndex === 2 &&
            ticketsTabCarouselData?.locations?.length === 0 &&
            ticketsTabCarouselData?.isLoading
        ) {
            this.fetchTicketsNearbyLocations();
        }
        if (
            currentIndex === 3 &&
            permitsTabCarouselData?.locations?.length === 0 &&
            permitsTabCarouselData?.isLoading
        ) {
            this.fetchPermitsNearbyLocations();
        }
        if (
            currentIndex === 4 &&
            dayUseVenuesTabCarouselData?.locations?.length === 0 &&
            dayUseVenuesTabCarouselData?.isLoading
        ) {
            this.fetchDayUseVenuesNearbyLocations();
        }
    };

    handleSearchTabChange = (newIndex) => {
        this.handleGetAvailableThisWeekend(Number(`${newIndex}`));
        this.setState({ activeSearchTabIndex: Number(`${newIndex}`) });
        SessionStorage.setItem('home-active-search-tab-index', newIndex);
    };

    handleSearchFilterChange = (event) => {
        const value = event.target.value;
        this.setState({
            waysToStay: value,
        });
    };

    onWaysToStayApplyClicked = () => {};

    submitTicketsSearch = () => {
        const { search } = this.props;

        try {
            const suggestion = search.selectedSuggestion || {
                text: search.what,
            };

            const uiFilters = this.applyTicketsUIFilters();

            this.setState({ searchLoading: true });

            suggestion.checkin_time = search.checkin_time;
            suggestion.checkout_time = search.checkout_time;
            suggestion.uiFilters = uiFilters;
            SearchActions.addSearchHistoryItem({
                rawSearchText: search.what,
                suggestion,
            });

            const tourTimeFilter = this.buildTourTimeFilter();

            SearchActions.fetchInventoryAvailabilityResults(
                search.what,
                suggestion?.entity_id,
                suggestion?.entity_type,
                uiFilters,
                search.checkin_time,
                search.checkout_time,
                null,
                true,
                true,
                null,
                null,
                tourTimeFilter
            )
                .then((result) => {
                    // clear some camping item that causes filters to be reset
                    LocalStorage.removeItem('last-visited-campground');
                    let firstResult = null;
                    if (result.data.results && result.data.results.length > 0) {
                        firstResult = result.data.results[0];
                        if (
                            search.what?.toLowerCase().trim() ===
                                firstResult.name.toLowerCase() &&
                            firstResult.entity_type === 'ticketfacility'
                        ) {
                            const url = MapHelper.getTargetUri(firstResult);

                            window.location.assign(url);

                            return;
                        }
                    }
                    this.showMainSearchResults('Tickets');
                })
                .catch(() => {
                    this.showMainSearchResults('Tickets');
                });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('error', err);

            this.setState({ searchLoading: false });
        }
    };

    submitPermitsSearch = () => {
        const { search } = this.props;

        try {
            const suggestion = search.selectedSuggestion || {
                text: search.what,
            };

            const uiFilters = this.applyPermitsUIFilters();

            this.setState({ searchLoading: true });

            suggestion.uiFilters = uiFilters;
            SearchActions.addSearchHistoryItem({
                rawSearchText: search.what,
                suggestion,
            });

            SearchActions.fetchInventoryAvailabilityResults(
                search.what,
                suggestion?.entity_id,
                suggestion?.entity_type,
                uiFilters,
                search.checkin_time,
                search.checkout_time
            )
                .then((result) => {
                    // clear some camping item that causes filters to be reset
                    LocalStorage.removeItem('last-visited-campground');
                    let firstResult = null;
                    if (result.data.results && result.data.results.length > 0) {
                        firstResult = result.data.results[0];
                        if (
                            search.what?.toLowerCase().trim() ===
                                firstResult.name.toLowerCase() &&
                            firstResult.entity_type === 'permit'
                        ) {
                            const url = MapHelper.getTargetUri(firstResult);

                            window.location.assign(url);

                            return;
                        }
                    }
                    this.showMainSearchResults('Permits');
                })
                .catch(() => {
                    this.showMainSearchResults('Permits');
                });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('error', err);

            this.setState({ searchLoading: false });
        }
    };

    submitDayUseVenuesSearch = () => {
        const { search } = this.props;

        try {
            const suggestion = search.selectedSuggestion || {
                text: search.what,
            };

            const uiFilters = this.applyDayUseVenuesUiFilters();

            this.setState({ searchLoading: true });

            suggestion.checkin_time = search.checkin_time;
            suggestion.checkout_time = search.checkout_time;
            suggestion.uiFilters = uiFilters;
            SearchActions.addSearchHistoryItem({
                rawSearchText: search.what,
                suggestion,
            });

            SearchActions.fetchInventoryAvailabilityResults(
                search.what,
                suggestion?.entity_id,
                suggestion?.entity_type,
                uiFilters,
                search.checkin_time,
                search.checkout_time
            )
                .then((result) => {
                    // clear some camping item that causes filters to be reset
                    LocalStorage.removeItem('last-visited-campground');

                    let firstResult = null;

                    if (result.data.results && result.data.results.length > 0) {
                        firstResult = result.data.results[0];
                        if (
                            search.what?.toLowerCase().trim() ===
                                firstResult.name.toLowerCase() &&
                            (firstResult.campsite_type_of_use.includes('Day') ||
                                firstResult.campsite_type_of_use.includes('Overnight'))
                        ) {
                            const url = MapHelper.getTargetUri(firstResult);

                            window.location.assign(url);

                            return;
                        }
                    }
                    this.showMainSearchResults('Day Use / Venues');
                })
                .catch(() => {
                    this.showMainSearchResults('Day Use / Venues');
                });
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('error', err);

            this.setState({ searchLoading: false });
        }
    };

    shouldRenderAccessibleCamping = () => {
        const { activeSearchTabIndex } = this.state;

        if (activeSearchTabIndex === 0 || activeSearchTabIndex === 1) {
            return <AccessibleCheckbox iconSize="sm" />;
        }

        return null;
    };

    getClickTagActionComplement = () => {
        const { search } = this.props;

        if (search.what) {
            return ` - ${search.what}`;
        }

        return '';
    };

    buildGATrackingObject = (suggestion, tab, isNearMe = false) => {
        let clickTagAction = '';
        const clickTagCategory = 'Homepage';
        const clickTagLabel = 'Hero';
        if (suggestion) {
            clickTagAction = `${tab}${
                suggestion.last_used ? ' History' : ''
            } Suggestion - ${suggestion.name}`;
        }

        if (isNearMe) {
            clickTagAction = `${tab} - Select Search Suggestion Near Me`;
        }

        return {
            clickTagCategory,
            clickTagAction,
            clickTagLabel,
        };
    };

    onFlexFiltersChange = (flexDatesRaw) => {
        const params = { ...flexDatesRaw };

        if (isEmpty(params)) {
            this.setState({ flexDatesFilter: {} });
            return;
        }

        if (params?.duration && params?.months) {
            params.startDate = null;
            params.endDate = null;
        } else {
            params.duration = null;
            params.months = null;
        }
        this.setState({
            flexDatesFilter: params,
        });
    };

    getCampingWaysToStayList = () => {
        const { flags } = this.props;
        if (flags?.useNewCampingFilters) {
            return [
                {
                    id: `homepage-search-options-all`,
                    className: 'mb-2',
                    label: 'All',
                    value: 'All',
                },
                {
                    id: `homepage-search-options-rv-camping`,
                    className: 'mb-2',
                    label: 'RV/Motorhome/Trailer Camping',
                    value: 'RV/Motorhome/Trailer Camping',
                },
                {
                    id: `homepage-search-options-tent-camping`,
                    className: 'mb-2',
                    label: 'Tent Camping',
                    value: 'Tent Camping',
                },
                {
                    id: `homepage-search-options-cabin-yurts`,
                    label: 'Lodging (Cabins/Yurts/Lookouts)',
                    value: 'Lodging (Cabins/Yurts/Lookouts)',
                },
            ];
        }
        return [
            {
                id: `homepage-search-options-all`,
                className: 'mb-2',
                label: 'All',
                value: 'All',
            },
            {
                id: `homepage-search-options-tent-camping`,
                className: 'mb-2',
                label: 'Tent Camping',
                value: 'Tent Camping',
            },
            {
                id: `homepage-search-options-rv-camping`,
                className: 'mb-2',
                label: 'RV Camping',
                value: 'RV Camping',
            },
            {
                id: `homepage-search-options-cabin-yurts`,
                label: 'Cabin / Yurts',
                value: 'Cabin / Yurts',
            },
        ];
    };

    render() {
        const {
            flexDatesFilter,
            startDate,
            useMobile,
            waysToStay,
            windowWidth,
            searchLoading,
            photoFlyout,
            activeSearchTabIndex,
            campingTabCarouselData,
            ticketsTabCarouselData,
            permitsTabCarouselData,
            dayUseVenuesTabCarouselData,
            searchPlaceholder: stateSearchPlaceholder,
            searchPlaceholderCamping: stateSearchPlaceholderCamping,
        } = this.state;
        const { submitSimpleSearch, flags } = this.props;

        // Hero Image Info
        const heroBgImg = this.randomHeroImage.heroBgImg;
        const locationName = this.randomHeroImage.locationName;
        const locationLink = this.randomHeroImage.locationLink;
        const photoCredit = this.randomHeroImage.photoCredit;
        const altText = this.randomHeroImage.altText;

        let searchPlaceholder = stateSearchPlaceholder;
        const searchPlaceholderCamping = stateSearchPlaceholderCamping;

        const heroImageSeasonYear = 'fall-24';
        const imageSpecs = [
            {
                size: 'lg',
                width: '2000w',
            },
            {
                size: 'md',
                width: '1440w',
            },
            {
                size: 'sm',
                width: '768w',
            },
            {
                size: 'xs',
                width: '375w',
            },
        ];

        // setup the hero image(s)
        const pictureSource = (imageFormat) => {
            const srcImage = () =>
                imageSpecs.map((imageSpec) => {
                    return `${process.env.CDN}/img/homepage/hero-section/${heroImageSeasonYear}-${heroBgImg}-${imageSpec.size}.${imageFormat} ${imageSpec.width}`;
                });

            return imageFormat === 'jpg' ? (
                <img
                    alt={altText}
                    src={`${process.env.CDN}/img/homepage/hero-section/${heroImageSeasonYear}-${heroBgImg}-md.${imageFormat}`}
                    srcSet={`${srcImage()}`}
                />
            ) : (
                <source
                    type={`image/${imageFormat}`}
                    sizes="100vw"
                    srcSet={`${srcImage()}`}
                />
            );
        };

        if (useMobile) {
            searchPlaceholder = 'Search for camping, tours and more...';
        }

        const defaultDropdownText = 'Ways to Stay';
        const waysToStayFilterLabel =
            waysToStay === 'All' ? (
                <>
                    <Icons.IconTent />
                    {defaultDropdownText}
                </>
            ) : (
                <>
                    <Icons.IconTent />
                    {waysToStay}
                </>
            );

        const navSearchCarouselItemsWidth = 208;

        if (windowWidth < 768) {
            return (
                <MobileSearch
                    isLoading={searchLoading}
                    submitSearch={this.submitSearch}
                    runSearch={this.runSearch}
                    pictureSource={pictureSource}
                />
            );
        }

        const viewMoreBtn = ({ uiFilters, key }) => {
            const goToResults = (e) => {
                e.preventDefault();

                const { trackRunSearch } = SearchActions;
                const { dispatch } = this.props;
                const gaEvent = {
                    category: 'Homepage',
                    action: `View all ${key} carousel link`,
                    label: 'Hero',
                };

                trackRunSearch(gaEvent, () => {
                    let { searchAcrossUS } = process.env.HOMEPAGE_OPTIONS;
                    let { bounds } = process.env.SEARCH;
                    if (!searchAcrossUS && !bounds) {
                        // default to search across us for some inventory types
                        searchAcrossUS = key === 'permits' || key === 'tours';
                    }
                    let dates = null;
                    if (this.props?.flags?.availableThisWeekend) {
                        const range = key === 'camping' ? [5, 7] : [6, 7];
                        const [start, end] = this.generateWeekendDates(...range);
                        dates = {
                            startDate: start,
                            endDate: end,
                        };
                    }
                    const geoipResult = this.state.geoIp;
                    const userIsWithinUS = isWithinUS(
                        geoipResult.latitude,
                        geoipResult.longitude
                    );

                    if (!userIsWithinUS && !bounds) {
                        bounds = {
                            lat_sw: 32.5341,
                            lng_sw: -124.482,
                            lat_ne: 42.0095,
                            lng_ne: -114.1315,
                        };
                    }
                    if ((key === 'tours' || key === 'permits') && !bounds) {
                        bounds = {
                            lng_sw: -167.2815442995964,
                            lat_sw: 3.6722362889127,
                            lng_ne: -51.637969429829155,
                            lat_ne: 60.77726184625004,
                        };
                    }
                    dispatch(
                        searchActions.submitSimpleSearch(
                            '',
                            [],
                            null,
                            null,
                            uiFilters,
                            searchAcrossUS,
                            bounds,
                            'available',
                            dates,
                            this.props?.flags?.availableThisWeekend
                        )
                    );
                });
            };
            return (
                <Button
                    appearance="link"
                    iconAfterElement={<Icons.IconArrowForward />}
                    key={`${key}Container`}
                    onClick={goToResults}
                >
                    View More
                </Button>
            );
        };

        const allowedInventoryTypes = process.env.ALLOWED_INVENTORY_TYPES;
        const [campingStartDate, campingEndDate] = this.generateWeekendDates(5, 7);
        const [venuesStartDate, venuesEndDate] = this.generateWeekendDates(6, 7);
        const [ticketStartDate, ticketEndDate] = this.generateWeekendDates(6, 7);
        const enableAvailableThisWeekend = flags?.availableThisWeekend;
        const waysToStayList = this.getCampingWaysToStayList();

        return (
            <section className="nav-hero-wrap-new">
                <div className="nav-hero-content-wrap">
                    <PhotoInfoFlyout
                        isOpen={photoFlyout}
                        open={this.openPhotoCredit}
                        close={this.closePhotoCredit}
                    >
                        <p>
                            <strong>Photo Location:</strong>{' '}
                            <Button
                                appearance="link"
                                isUnstyled
                                href={`${locationLink}`}
                                aria-label={`Visit ${locationName}`}
                                clickTagCategory="Homepage"
                                clickTagAction={`Image Location Link to - ${locationName}`}
                                clickTagLabel="Hero"
                            >
                                {locationName}
                            </Button>
                            {photoCredit && (
                                <>
                                    {' '}
                                    | <strong>Photo Credit:</strong> {photoCredit}
                                </>
                            )}
                        </p>
                    </PhotoInfoFlyout>

                    <FlexRow hasGutters={false}>
                        <FlexCol>
                            <div
                                className={cx('nav-hero-search-section-wrap-new', {
                                    'photo-fly-out': photoFlyout,
                                })}
                            >
                                {photoFlyout && (
                                    <div
                                        className="photo-fly-out-layer"
                                        onClick={this.closePhotoCredit}
                                        aria-hidden="true"
                                    />
                                )}
                                <TabsNew
                                    stretchTabsToFit={false}
                                    activeIndex={activeSearchTabIndex}
                                    onChange={this.handleSearchTabChange}
                                    tabsAccessoryComponent={this.shouldRenderAccessibleCamping()}
                                >
                                    <TabPanel
                                        label="Explore All"
                                        clickTagCategory={tabPanelGACategory}
                                        clickTagAction="Explore All - search tab"
                                        clickTagLabel={tabPanelGALabel}
                                    >
                                        <div className="nav-search-input-wrapper">
                                            <SearchInputContainer
                                                buildGATrackingObject={(
                                                    suggestion,
                                                    isNearMe
                                                ) =>
                                                    this.buildGATrackingObject(
                                                        suggestion,
                                                        'Explore All',
                                                        isNearMe
                                                    )
                                                }
                                                placeholder={searchPlaceholder}
                                                onRunSearch={this.runSearch}
                                                searchInputID="hero-search-input"
                                                disableSearchOnSelect={false}
                                                showAlways
                                            />

                                            <Button
                                                className="nav-search-button"
                                                clickTagCategory="Homepage"
                                                clickTagAction={`Explore All Search Initiated${this.getClickTagActionComplement()}`}
                                                clickTagLabel="Hero"
                                                isLoading={searchLoading}
                                                onClick={this.submitSearch}
                                            >
                                                SEARCH
                                            </Button>
                                        </div>

                                        <div>
                                            <Divider />

                                            <ExploreAllQuickPromos
                                                search={submitSimpleSearch}
                                            />
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        label="Camping & Lodging"
                                        isHidden={!allowedInventoryTypes?.camping}
                                        clickTagCategory={tabPanelGACategory}
                                        clickTagAction="Camping & Lodging - search tab"
                                        clickTagLabel={tabPanelGALabel}
                                    >
                                        <div className="nav-search-input-wrapper">
                                            <SearchInputContainer
                                                buildGATrackingObject={(
                                                    suggestion,
                                                    isNearMe
                                                ) =>
                                                    this.buildGATrackingObject(
                                                        suggestion,
                                                        'Camping & Lodging',
                                                        isNearMe
                                                    )
                                                }
                                                placeholder={searchPlaceholderCamping}
                                                onRunSearch={this.submitCampingSearch}
                                                searchInputID="hero-camping-search-input"
                                                inventoryTypes={['campground', 'recarea']}
                                                searchOnSelectTypes={[
                                                    'kb',
                                                    'cms',
                                                    'article',
                                                ]}
                                                disableSearchOnSelect
                                                geocoder
                                                showAlways
                                            />

                                            <DropdownMenu
                                                id="homepage-search-options"
                                                label={waysToStayFilterLabel}
                                                onApply={this.onWaysToStayApplyClicked}
                                                className={
                                                    waysToStayFilterLabel ===
                                                    defaultDropdownText
                                                        ? ''
                                                        : 'homepage-active-dropdown-text'
                                                }
                                            >
                                                <div style={{ minWidth: '200px' }}>
                                                    <RadioGroup
                                                        name="homepage-search-camping-options"
                                                        value={waysToStay}
                                                        onChange={
                                                            this.handleSearchFilterChange
                                                        }
                                                    >
                                                        {waysToStayList.map((item) => (
                                                            <Radio
                                                                key={item.id}
                                                                id={item.id}
                                                                className={item.className}
                                                                label={item.label}
                                                                value={item.value}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                </div>
                                            </DropdownMenu>

                                            <div className="nav-search-flex-dates">
                                                <FlexDates
                                                    monthsLimit={4}
                                                    onFiltersChange={
                                                        this.onFlexFiltersChange
                                                    }
                                                    filters={flexDatesFilter}
                                                />
                                            </div>

                                            <Button
                                                className="nav-search-button"
                                                clickTagCategory="Homepage"
                                                clickTagAction={`Camping & Lodging Search Initiated${this.getClickTagActionComplement()}`}
                                                clickTagLabel="Hero"
                                                onClick={this.submitCampingSearch}
                                                isLoading={searchLoading}
                                                isDisabled={searchLoading}
                                            >
                                                SEARCH
                                            </Button>
                                        </div>

                                        <div>
                                            <Divider />

                                            <FlexRow>
                                                <FlexCol className="hero-inventory-section-heading-wrap">
                                                    {!enableAvailableThisWeekend && (
                                                        <Heading
                                                            headingLevel={2}
                                                            appearance="h6"
                                                        >
                                                            Camping Locations
                                                        </Heading>
                                                    )}
                                                    {enableAvailableThisWeekend && (
                                                        <div>
                                                            <Heading
                                                                headingLevel={2}
                                                                appearance="h6"
                                                            >
                                                                Available This Weekend
                                                            </Heading>
                                                            <Text className="py-1">
                                                                Check In:{' '}
                                                                {campingStartDate.format(
                                                                    'ddd'
                                                                )}
                                                                .,{' '}
                                                                {campingStartDate.format(
                                                                    'MMMM D'
                                                                )}
                                                                , Check Out:{' '}
                                                                {campingEndDate.format(
                                                                    'ddd'
                                                                )}
                                                                .,{' '}
                                                                {campingEndDate.format(
                                                                    'MMMM D'
                                                                )}
                                                            </Text>
                                                        </div>
                                                    )}

                                                    {viewMoreBtn({
                                                        uiFilters: {
                                                            inventoryTypes:
                                                                HOMEPAGE_HERO_INVENTORY.CAMPING_TAB,
                                                        },
                                                        inventoryDisplayName:
                                                            'Campgrounds',
                                                        key: 'camping',
                                                    })}
                                                </FlexCol>
                                            </FlexRow>

                                            <CampingLodgingCarousel
                                                itemWidth={navSearchCarouselItemsWidth}
                                                data={campingTabCarouselData}
                                                enableAvailableThisWeekend={
                                                    enableAvailableThisWeekend
                                                }
                                                dates={{
                                                    startDate: campingStartDate,
                                                    endDate: campingEndDate,
                                                }}
                                            />
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        label={
                                            <FeatureFlag
                                                flag="isActivitiesTab"
                                                fallback="Tickets & Tours"
                                            >
                                                Activities & Tickets
                                            </FeatureFlag>
                                        }
                                        isHidden={!allowedInventoryTypes?.tickets}
                                        clickTagCategory={tabPanelGACategory}
                                        clickTagAction="Tickets & Tours - search tab"
                                        clickTagLabel={tabPanelGALabel}
                                    >
                                        <div className="nav-search-input-wrapper">
                                            <SearchInputContainer
                                                buildGATrackingObject={(
                                                    suggestion,
                                                    isNearMe
                                                ) =>
                                                    this.buildGATrackingObject(
                                                        suggestion,
                                                        'Tickets & Tours',
                                                        isNearMe
                                                    )
                                                }
                                                placeholder="Search by location or facility name"
                                                onRunSearch={this.submitTicketsSearch}
                                                searchInputID="hero-tickets-search-input"
                                                inventoryTypes={[
                                                    'ticketfacility',
                                                    'timedentry',
                                                ]}
                                                searchOnSelectTypes={[
                                                    'kb',
                                                    'cms',
                                                    'article',
                                                ]}
                                                disableSearchOnSelect
                                                geocoder
                                                showAlways
                                            />

                                            <TourTimeFilter
                                                onTourTimeChange={this.onTourTimeChange}
                                            />

                                            <DatePicker
                                                className="hero-date-picker"
                                                size="lg"
                                                label="Tickets & Tours date picker"
                                                isLabelVisible={false}
                                                value={momentToCalendarDate(startDate)}
                                                onChange={this.onSingleDateChange}
                                            />

                                            <Button
                                                className="nav-search-button"
                                                clickTagCategory="Homepage"
                                                clickTagAction={`Tickets & Tours Search Initiated${this.getClickTagActionComplement()}`}
                                                clickTagLabel="Hero"
                                                isLoading={searchLoading}
                                                isDisabled={searchLoading}
                                                onClick={this.submitTicketsSearch}
                                            >
                                                SEARCH
                                            </Button>
                                        </div>

                                        <div>
                                            <Divider />

                                            <FlexRow>
                                                <FlexCol className="hero-inventory-section-heading-wrap">
                                                    {!enableAvailableThisWeekend && (
                                                        <Heading
                                                            headingLevel={2}
                                                            appearance="h6"
                                                        >
                                                            Tickets & Tours Locations
                                                        </Heading>
                                                    )}

                                                    {enableAvailableThisWeekend && (
                                                        <div>
                                                            <Heading
                                                                headingLevel={2}
                                                                appearance="h6"
                                                            >
                                                                Available This Weekend
                                                            </Heading>
                                                            <Text className="py-1">
                                                                Options for{' '}
                                                                {ticketStartDate.format(
                                                                    'ddd'
                                                                )}
                                                                .,{' '}
                                                                {ticketStartDate.format(
                                                                    'MMMM D'
                                                                )}{' '}
                                                                or{' '}
                                                                {ticketEndDate.format(
                                                                    'ddd'
                                                                )}
                                                                .,{' '}
                                                                {ticketEndDate.format(
                                                                    'MMMM D'
                                                                )}
                                                            </Text>
                                                        </div>
                                                    )}

                                                    {viewMoreBtn({
                                                        uiFilters: {
                                                            inventoryTypes:
                                                                HOMEPAGE_HERO_INVENTORY.TICKETS_TOURS_TAB,
                                                        },
                                                        inventoryDisplayName:
                                                            'Tickets & Tours',
                                                        key: 'tours',
                                                    })}
                                                </FlexCol>
                                            </FlexRow>

                                            <TicketsToursCarousel
                                                itemWidth={navSearchCarouselItemsWidth}
                                                data={ticketsTabCarouselData}
                                                enableAvailableThisWeekend={
                                                    enableAvailableThisWeekend
                                                }
                                                dates={{
                                                    startDate: ticketStartDate,
                                                    endDate: ticketEndDate,
                                                }}
                                            />
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        label="Permits"
                                        isHidden={!allowedInventoryTypes?.permits}
                                        clickTagCategory={tabPanelGACategory}
                                        clickTagAction="Permits - search tab"
                                        clickTagLabel={tabPanelGALabel}
                                    >
                                        <div className="nav-search-input-wrapper">
                                            <SearchInputContainer
                                                buildGATrackingObject={(
                                                    suggestion,
                                                    isNearMe
                                                ) =>
                                                    this.buildGATrackingObject(
                                                        suggestion,
                                                        'Permits',
                                                        isNearMe
                                                    )
                                                }
                                                placeholder="Search by location or facility name"
                                                onRunSearch={this.submitPermitsSearch}
                                                searchInputID="hero-permits-input"
                                                inventoryTypes={['permit']}
                                                showAlways
                                            />

                                            <Button
                                                className="nav-search-button"
                                                clickTagCategory="Homepage"
                                                clickTagAction={`Permits Search Initiated${this.getClickTagActionComplement()}`}
                                                clickTagLabel="Hero"
                                                isLoading={searchLoading}
                                                isDisabled={searchLoading}
                                                onClick={this.submitPermitsSearch}
                                            >
                                                SEARCH
                                            </Button>
                                        </div>

                                        <div>
                                            <Divider />

                                            <FlexRow>
                                                <FlexCol className="hero-inventory-section-heading-wrap">
                                                    <Heading
                                                        headingLevel={2}
                                                        appearance="h6"
                                                    >
                                                        Permit Locations
                                                    </Heading>

                                                    {viewMoreBtn({
                                                        uiFilters: {
                                                            inventoryTypes:
                                                                HOMEPAGE_HERO_INVENTORY.PERMITS_TAB,
                                                        },
                                                        inventoryDisplayName:
                                                            'Permit Locations',
                                                        key: 'permits',
                                                    })}
                                                </FlexCol>
                                            </FlexRow>

                                            <PermitsCarousel
                                                itemWidth={navSearchCarouselItemsWidth}
                                                data={permitsTabCarouselData}
                                            />
                                        </div>
                                    </TabPanel>

                                    <TabPanel
                                        label="Day Use / Venues"
                                        isHidden={!allowedInventoryTypes?.venues}
                                        clickTagCategory={tabPanelGACategory}
                                        clickTagAction="Day Use / Venues - search tab"
                                        clickTagLabel={tabPanelGALabel}
                                    >
                                        <div className="nav-search-input-wrapper">
                                            <SearchInputContainer
                                                buildGATrackingObject={(
                                                    suggestion,
                                                    isNearMe
                                                ) =>
                                                    this.buildGATrackingObject(
                                                        suggestion,
                                                        'Day Use / Venues',
                                                        isNearMe
                                                    )
                                                }
                                                placeholder="Search by location or facility name"
                                                onRunSearch={
                                                    this.submitDayUseVenuesSearch
                                                }
                                                searchInputID="hero-dayuse-venues-input"
                                                inventoryTypes={[
                                                    'dayuse',
                                                    'venuereservations',
                                                ]}
                                                searchOnSelectTypes={[
                                                    'kb',
                                                    'cms',
                                                    'article',
                                                ]}
                                                disableSearchOnSelect
                                                showAlways
                                            />

                                            <DatePicker
                                                label="Day Use / Venues date picker"
                                                className="hero-date-picker"
                                                value={momentToCalendarDate(startDate)}
                                                size="lg"
                                                isLabelVisible={false}
                                                onChange={this.onSingleDateChange}
                                            />

                                            <Button
                                                className="nav-search-button"
                                                clickTagCategory="Homepage"
                                                clickTagAction={`Day Use / Venues Search Initiated${this.getClickTagActionComplement()}`}
                                                clickTagLabel="Hero"
                                                isLoading={searchLoading}
                                                isDisabled={searchLoading}
                                                onClick={this.submitDayUseVenuesSearch}
                                            >
                                                SEARCH
                                            </Button>
                                        </div>

                                        <div>
                                            <Divider />

                                            <FlexRow>
                                                <FlexCol className="hero-inventory-section-heading-wrap">
                                                    {!enableAvailableThisWeekend && (
                                                        <Heading
                                                            headingLevel={2}
                                                            appearance="h6"
                                                        >
                                                            Day Use / Venues Locations
                                                        </Heading>
                                                    )}
                                                    {enableAvailableThisWeekend && (
                                                        <div>
                                                            <Heading
                                                                headingLevel={2}
                                                                appearance="h6"
                                                            >
                                                                Available This Weekend
                                                            </Heading>
                                                            <Text className="py-1">
                                                                Options for{' '}
                                                                {venuesStartDate.format(
                                                                    'ddd'
                                                                )}
                                                                .,{' '}
                                                                {venuesStartDate.format(
                                                                    'MMMM D'
                                                                )}{' '}
                                                                or{' '}
                                                                {venuesEndDate.format(
                                                                    'ddd'
                                                                )}
                                                                .,{' '}
                                                                {venuesEndDate.format(
                                                                    'MMMM D'
                                                                )}
                                                            </Text>
                                                        </div>
                                                    )}
                                                    {viewMoreBtn({
                                                        uiFilters: {
                                                            inventoryTypes:
                                                                HOMEPAGE_HERO_INVENTORY.DAY_USE_VENUES_TAB,
                                                        },
                                                        inventoryName:
                                                            'dayuse&inventory_type=venuereservations',
                                                        inventoryDisplayName:
                                                            'Day Use / Venues',
                                                        key: 'dayUseVenues',
                                                    })}
                                                </FlexCol>
                                            </FlexRow>

                                            <DayUseVenuesCarousel
                                                itemWidth={navSearchCarouselItemsWidth}
                                                data={dayUseVenuesTabCarouselData}
                                                enableAvailableThisWeekend={
                                                    enableAvailableThisWeekend
                                                }
                                                dates={{
                                                    startDate: venuesStartDate,
                                                    endDate: venuesEndDate,
                                                }}
                                            />
                                        </div>
                                    </TabPanel>
                                    <TabPanel
                                        label={
                                            <FlexRow>
                                                <FlexCol>
                                                    AI Search{' '}
                                                    <Badge
                                                        className="ml-half"
                                                        appearance="info"
                                                    >
                                                        Beta
                                                    </Badge>
                                                </FlexCol>
                                            </FlexRow>
                                        }
                                        isHidden={!this.props.flags?.useIaSearchParse}
                                        clickTagCategory={tabPanelGACategory}
                                        clickTagAction=" AI Search - search tab"
                                        clickTagLabel={tabPanelGALabel}
                                    >
                                        <HeroAiSearch />
                                    </TabPanel>
                                </TabsNew>
                            </div>
                        </FlexCol>
                    </FlexRow>
                </div>

                <picture>
                    {/* Show the hero image as an .avif */}
                    {pictureSource('avif')}

                    {/* If the browser can't handle an .avif, try a .webP */}
                    {pictureSource('webp')}

                    {/* If the browser can't handle an .avif or .webP, go with .jpg */}
                    {pictureSource('jpg')}
                </picture>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        search: state.search,
    };
};

export default withLDConsumer()(connect(mapStateToProps)(HeroContainer));

// cSpell:ignore Carrizo, Chah, Koustav, Maity, Eldorado, Rayna, Nickoson, Shandra, Mondragon, Asmussen, Lathrop, Cimarron, Fambrini, Midewin, Tallgrass, USFS, ticketfacility, dayuse, RECAREA, checkin, geoip, venuereservations, Criterias, timedentry, ACTIVITYPASS, Gifford, Pinchot, tickettours, destin, frasier, smilanic, schermer
