/* © 2017-2024 Booz Allen Hamilton Inc. All Rights Reserved. */

import {
    gaProductObject,
    gaGenerateCheckoutEvent,
    CART_GA_PRODUCT_REMOVE_FROM_CART_KEY,
    breakApartAndGetIdFromProductId,
} from 'sarsaparilla';
import { isInteger } from 'lodash';
import {
    INVENTORY_LOTTERY_CAMPING,
    INVENTORY_CAMPING,
    INVENTORY_POS,
    INVENTORY_TICKET,
    INVENTORY_TIMED_ENTRY,
} from 'ui-field-sales/src/constants/types';

/**
 * gaExtractProductIdAndCategoryFromReservation - gets a productId and category from a cart reservation
 * @param {Object} reservation - a cart-based reservation
 * @returns { productId, category } - returns an id and category for a reservation
 */
export const gaExtractProductIdAndCategoryFromReservation = (reservation) => {
    let productId = reservation?.order_details?.location_id;
    const category = reservation?.inventory_type;
    if (category) {
        switch (category) {
            case INVENTORY_TICKET:
            case INVENTORY_TIMED_ENTRY:
                productId += `|${reservation?.order_details?.tour_id}`;
                break;
            case INVENTORY_CAMPING:
            case INVENTORY_LOTTERY_CAMPING:
                productId += `|${reservation?.order_details?.campsite_id}`;
                break;
            default:
                break;
        }
    }
    return {
        productId,
        category,
    };
};

/**
 * gaReportingCartReservationsForStepper - this function is used specifically to submit cart reservations for a specific event type
 * @param {Array<Object>} reservations - array of reservations currently in cart
 * @param {Array<string>} steps - used from ProgressIndicator.jsx, an array of strings with corresponding name
 * @param {number} trueStep - current step (WARNING - THIS IS A ZERO-BASED INDEX, the component typically uses a 1-based index)
 *                            WARNING - trueStep should be -1 of active step reported to the stepper
 * @returns {<Object>} - an event object ready to be submitted to data layer
 */
export const gaReportingCartReservationsForStepper = (reservations, steps, trueStep) => {
    try {
        if (!reservations?.length) return null;
        const orderId = reservations?.[0]?.order_id;
        const products = reservations.map((reservation, idx) => {
            let quantity = parseInt(reservation?.quantity || 0, 10) || 1;

            // if unable to find quantity, try to use cart lineitems as a fallback
            if (!quantity) {
                quantity =
                    reservation?.cart_line_items
                        ?.filter((lineItem) => isInteger(lineItem.quantity))
                        ?.reduce((prev, next) => ({
                            quantity: prev.quantity + next.quantity,
                        }))?.quantity || 1;
            }

            const name =
                reservation.inventory_type === INVENTORY_POS
                    ? reservation.order_details.name
                    : reservation.order_details.description;

            const discount = parseFloat(parseFloat(reservation.discount).toFixed(2));
            const subtotal = parseFloat(parseFloat(reservation.subtotal).toFixed(2));
            const price = parseFloat(
                parseFloat(((subtotal - discount) / quantity).toFixed(2))
            );

            const { productId, category } =
                gaExtractProductIdAndCategoryFromReservation(reservation);
            // NOTE - shipping is not standard and cannot be displayed here sadly
            return gaProductObject({
                item_id: productId,
                item_name: name,
                item_brand: 'Recreation.gov',
                item_category: category,
                quantity,
                price,
                index: idx + 1,
                facility_id: breakApartAndGetIdFromProductId(productId, 0),
                location_id: breakApartAndGetIdFromProductId(productId, 1),
            });
        });
        // return this back to be reported to google analytics
        return gaGenerateCheckoutEvent({
            id: orderId,
            step_name: steps?.[trueStep],
            step_number: trueStep + 1,
            items: products,
        });
    } catch (e) {
        console.error(e);
        return null;
    }
};

/**
 * This utility function sets a value in session storage for possible downstream google analytics reporting
 *
 * This uses sessionstorage and is very ephemeral, will drop on changing tabs or windows.
 */
export const gaSetRemoveFromCartEvent = () => {
    try {
        // this MUST be handled for google analytics, will either be purged or reused
        sessionStorage.setItem(
            CART_GA_PRODUCT_REMOVE_FROM_CART_KEY,
            window?.location?.href
        );
    } catch (e) {
        console.error(e);
    }
};
